// songs/songList.js
const songList = [
    // Clarinet
    { title: "Solo de Concours", file: "Anthony Mcgill - Solo de Concours.mp3", instrument: "clarinet" },
    { title: "Contrasts- Verbunkos", file: "BÃ©la BartÃ³k, Richard Stoltzman, Richard Goode, Lucy Chapman Stoltzman - Contrasts- Verbunkos.mp3", instrument: "clarinet" },
    { title: "Hommage a Manuel de Falla", file: "BÃ©la KovÃ¡cs, AndrÃ© Moisan - Hommages- Hommage a Manuel de Falla.mp3", instrument: "clarinet" },
    { title: "After You, Mr. Gershwin!", file: "BÃ©la KovÃ¡cs, AndrÃ© Moisan, Jean Saulnier - After You, Mr. Gershwin!.mp3", instrument: "clarinet" },
    { title: "Hommage Ã¡ Aram Khatschaturian", file: "BÃ©la KovÃ¡cs, Aram Khachaturian, Evgeni Orkin - Hommage Ã¡ Aram Khatschaturian for Clarinet Solo.mp3", instrument: "clarinet" },
    { title: "Sholem-alekhem, rov Feidman!", file: "BÃ©la KovÃ¡cs, Todd Levy, Jeannie Yu - Sholem-alekhem, rov Feidman!.mp3", instrument: "clarinet" },
    { title: "Introduction and Variations on a Swedish Song for Clarinet and Orchestra, Op. 12", file: "Bernhard Henrik Crusell, Martin FrÃ¶st, Royal Stockholm Philharmonic Orchestra - Introduction and Variations on a Swedish Song for Clarinet and Orchestra, Op. 12.mp3", instrument: "clarinet" },
    { title: "Sonate pour clarinette et piano, Op. 167- I. Allegretto", file: "Camille Saint-SaÃ«ns, Pierre GÃ©nisson, David Bismuth - Sonate pour clarinette et piano, Op. 167- I. Allegretto.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto No. 2 in E-Flat Major, Op. 74, J. 118- I. Allegro", file: "Carl Maria von Weber, Martin FrÃ¶st, Tapiola Sinfonietta, Jean-Jacques Kantorow - Clarinet Concerto No. 2 in E-Flat Major, Op. 74, J. 118- I. Allegro.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto No. 2 in E-Flat Major, Op. 74, J. 118- II. Romanza. Andante", file: "Carl Maria von Weber, Martin FrÃ¶st, Tapiola Sinfonietta, Jean-Jacques Kantorow - Clarinet Concerto No. 2 in E-Flat Major, Op. 74, J. 118- II. Romanza. Andante.mp3", instrument: "clarinet" },
    { title: "Clarinet Concertino in C Minor", file: "Carl Maria von Weber, Martin FrÃ¶st, Tapiola Sinfonietta, Jean-Jacques Kantorow - Concertino for Clarinet & Orchestra in E-Flat Major, Op. 26, J. 109- Clarinet Concertino in C Minor  E-Flat Major, Op. 26, J. 109.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto No. 7 in E Flat Major (DarmstÃ¤dter No. 1)- 3. Rondeau", file: "Carl Stamitz, Andreas Ottensamer, Kammerakademie Potsdam - Clarinet Concerto No. 7 in E Flat Major (DarmstÃ¤dter No. 1)- 3. Rondeau.mp3", instrument: "clarinet" },
    { title: "Premiere Rhapsody, L. 116", file: "Claude Debussy, Julian Bliss, Bradley Moore - Premiere Rhapsody, L. 116.mp3", instrument: "clarinet" },
    { title: "PremiÃ¨re rapsodie in B-Flat Major, CD 124, L. 116 (Orchestral Version)", file: "Claude Debussy, Sabine Meyer, Berliner Philharmoniker, Claudio Abbado - Debussy- PremiÃ¨re rapsodie in B-Flat Major, CD 124, L. 116 (Orchestral Version).mp3", instrument: "clarinet" },
    { title: "Old Klezmer Band", file: "Colalaila - Old Klezmer Band.mp3", instrument: "clarinet" },
    { title: "Scaramouche for Clarinet and Piano, Op. 165 d- I. Vif", file: "Darius Milhaud, Sebastian Manz, Martin Klett - Scaramouche for Clarinet and Piano, Op. 165 d- I. Vif.mp3", instrument: "clarinet" },
    { title: "Scaramouche for Clarinet and Piano, Op. 165 d- II. ModÃ©rÃ©", file: "Darius Milhaud, Sebastian Manz, Martin Klett - Scaramouche for Clarinet and Piano, Op. 165 d- II. ModÃ©rÃ©.mp3", instrument: "clarinet" },
    { title: "Scaramouche for Clarinet and Piano, Op. 165 d- III. Brazileira", file: "Darius Milhaud, Sebastian Manz, Martin Klett - Scaramouche for Clarinet and Piano, Op. 165 d- III. Brazileira.mp3", instrument: "clarinet" },
    { title: "Nature Boy", file: "Eden Ahbez, Martin FrÃ¶st, Torleif ThedÃ©en, Svante Henryson - Nature Boy (Arr. For clarinet, cello and double bass).mp3", instrument: "clarinet" },
    { title: "Adagio e Tarantella", file: "Ernesto Cavallini, United States Coast Guard Band, Artist Unknown - Adagio e Tarantella.mp3", instrument: "clarinet" },
    { title: "Oblivion", file: "Florent HÃ©au, Marcela Roggeri - Oblivion.mp3", instrument: "clarinet" },
    { title: "Concerto for Recorder, Transverse Flute, Strings and Continuo in E Minor- IV. Presto", file: "Georg Philipp Telemann, Martin FrÃ¶st, Royal Stockholm Philharmonic Orchestra - Concerto for Recorder, Transverse Flute, Strings and Continuo in E Minor- IV. Presto.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto in C Minor, Op. 31- I. Allegro vigoroso", file: "Gerald Finzi, Robert Plane, Royal Northern Sinfonia, Howard Griffiths - Clarinet Concerto in C Minor, Op. 31- I. Allegro vigoroso.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto in C Minor, Op. 31- II. Adagio, ma senza rigore", file: "Gerald Finzi, Robert Plane, Royal Northern Sinfonia, Howard Griffiths - Clarinet Concerto in C Minor, Op. 31- II. Adagio, ma senza rigore.mp3", instrument: "clarinet" },
    { title: "Clarinet Concerto in C Minor, Op. 31- III. Rondo- Allegro giocoso", file: "Gerald Finzi, Robert Plane, Royal Northern Sinfonia, Howard Griffiths - Clarinet Concerto in C Minor, Op. 31- III. Rondo- Allegro giocoso.mp3", instrument: "clarinet" },
    { title: "Introduction, Theme and Variations for Clarinet and Orchestra in E-Flat Major", file: "Gioachino Rossini, Orpheus Chamber Orchestra - Introduction, Theme and Variations for Clarinet and Orchestra in E-Flat Major - Cadenza- Charles Neidich- Introduzione- Andante (sostenuto).mp3", instrument: "clarinet" },
    { title: "Klezmer Dances", file: "GÃ¶ran FrÃ¶st, Martin FrÃ¶st, Australian Chamber Orchestra, Richard Tognetti - Klezmer Dances.mp3", instrument: "clarinet" },
    { title: "Klezmer Dance No. 2", file: "GÃ¶ran FrÃ¶st, Martin FrÃ¶st, Royal Stockholm Philharmonic Orchestra - Klezmer Dance No. 2.mp3", instrument: "clarinet" },
    { title: "Chance Dance", file: "Greg Mills, Eric Mandat - Chance Dance.mp3", instrument: "clarinet" },
    { title: "Tag Team", file: "Greg Mills, Eric Mandat - Tag Team.mp3", instrument: "clarinet" },
    { title: "Solo de concours, Op. 10", file: "Henri Rabaud, Michael Collins, Michael McHale - Solo de concours, Op. 10.mp3", instrument: "clarinet" },
    { title: "Ancient Suite", file: "Hildegard von Bingen, Martin FrÃ¶st, Adolf Fredriks FlickkÃ¶r - Ancient Suite.mp3", instrument: "clarinet" },
    { title: "The Tale Of Viktor Navorski - The Terminal", file: "John Williams - The Tale Of Viktor Navorski - The TerminalSoundtrack Version.mp3", instrument: "clarinet" },
    { title: "Sonatina for Clarinet and Piano- I. Allegro con brio", file: "Malcolm Arnold, Ronald Van Spaendonck, Eliane Reyes - Sonatina for Clarinet and Piano- I. Allegro con brio.mp3", instrument: "clarinet" },
    { title: "Nana", file: "Manuel de Falla, Martin FrÃ¶st, Royal Stockholm Philharmonic Orchestra - Nana.mp3", instrument: "clarinet" },
    { title: "Duo in E flat, Op. 15", file: "Norbert BurgmÃ¼ller, Bettina Aust, Robert Aust - Duo in E flat, Op. 15.mp3", instrument: "clarinet" },
    { title: "River of Crystal Light", file: "Peter Lieuwen, Texas Music Festival Orchestra, David Campbell, Franz Anton Krager, Moores School Symphony Orchestra - River of Crystal Light.mp3", instrument: "clarinet" },
    { title: "Souvenir d'un lieu cher, Op. 42- No. 3, MÃ©lodie in E-Flat Major", file: "Pyotr Ilyich Tchaikovsky, Sharon Kam, Itamar Golan - Souvenir d'un lieu cher, Op. 42- No. 3, MÃ©lodie in E-Flat Major.mp3", instrument: "clarinet" },
    { title: "Rolig Pers Polska", file: "Traditional, Martin FrÃ¶st, Royal Stockholm Philharmonic Orchestra - Rolig Pers Polska.mp3", instrument: "clarinet" },
    { title: "Rhapsody for Clarinet", file: "Willson Osborne, Karl Leister - Rhapsody for Clarinet.mp3", instrument: "clarinet" },

    // Guitar
    { title: "Falling in Love", file: "Andy Angel - (I Can't Help) Falling in Love - Guitar Version.mp3", instrument: "guitar" },
    { title: "911", file: "Andy Angel - 911 - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "All I Want Is You", file: "Andy Angel - All I Want Is You - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Are You With Me", file: "Andy Angel - Are You With Me - Guitar Version.mp3", instrument: "guitar" },
    { title: "Bette Davis Eyes", file: "Andy Angel - Bette Davis Eyes - Guitar Version.mp3", instrument: "guitar" },
    { title: "Black Hole Sun", file: "Andy Angel - Black Hole Sun - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Blinding Lights", file: "Andy Angel - Blinding Lights - Guitar Version.mp3", instrument: "guitar" },
    { title: "Circles", file: "Andy Angel - Circles - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Creep", file: "Andy Angel - Creep - Guitar Version.mp3", instrument: "guitar" },
    { title: "Do I Wanna Know", file: "Andy Angel - Do I Wanna Know - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Dreams", file: "Andy Angel - Dreams - Guitar Version.mp3", instrument: "guitar" },
    { title: "Everglow", file: "Andy Angel - Everglow - Guitar Version.mp3", instrument: "guitar" },
    { title: "Every Breath You Take", file: "Andy Angel - Every Breath You Take - Guitar Version.mp3", instrument: "guitar" },
    { title: "Everywhere", file: "Andy Angel - Everywhere - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Fast Car", file: "Andy Angel - Fast Car - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Free Falling", file: "Andy Angel - Free Falling - Guitar Version.mp3", instrument: "guitar" },
    { title: "Hallelujah", file: "Andy Angel - Hallelujah - Guitar Version.mp3", instrument: "guitar" },
    { title: "Here With Me", file: "Andy Angel - Here With Me - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Hey Brother", file: "Andy Angel - Hey Brother - Guitar Cover.mp3", instrument: "guitar" },
    { title: "I Can See Clearly Now", file: "Andy Angel - I Can See Clearly Now - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "I Don't Want to Miss a Thing", file: "Andy Angel - I Don't Want to Miss a Thing - Guitar Version.mp3", instrument: "guitar" },
    { title: "It Must Have Been Love", file: "Andy Angel - It Must Have Been Love - Guitar Version.mp3", instrument: "guitar" },
    { title: "Knockin' on Heaven's Door", file: "Andy Angel - Knockin' on Heaven's Door - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Last Night", file: "Andy Angel - Last Night - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Layla", file: "Andy Angel - Layla - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Let Her Go", file: "Andy Angel - Let Her Go - Guitar Version.mp3", instrument: "guitar" },
    { title: "Like a Prayer", file: "Andy Angel - Like a Prayer - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Livin' On A Prayer", file: "Andy Angel - Livin' On A Prayer - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Losing My Religion", file: "Andy Angel - Losing My Religion - Guitar Version.mp3", instrument: "guitar" },
    { title: "Love Yourself", file: "Andy Angel - Love Yourself - Guitar Version.mp3", instrument: "guitar" },
    { title: "Personal Jesus", file: "Andy Angel - Personal Jesus - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Poison", file: "Andy Angel - Poison - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Riptide", file: "Andy Angel - Riptide - Guitar Version.mp3", instrument: "guitar" },
    { title: "SNAP", file: "Andy Angel - SNAP - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Set Fire to the Rain", file: "Andy Angel - Set Fire to the Rain - Guitar Version.mp3", instrument: "guitar" },
    { title: "Shivers", file: "Andy Angel - Shivers - Guitar Version.mp3", instrument: "guitar" },
    { title: "Show Must Go On", file: "Andy Angel - Show Must Go On - Guitar Cover.mp3", instrument: "guitar" },
    { title: "The Sound of Silence", file: "Andy Angel - The Sound of Silence - Guitar Version.mp3", instrument: "guitar" },
    { title: "We're Not Gonna Take It", file: "Andy Angel - We're Not Gonna Take It - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Wild World", file: "Andy Angel - Wild World - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "With Or Without You", file: "Andy Angel - With Or Without You - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Without You", file: "Andy Angel - Without You - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Wonderwall", file: "Andy Angel - Wonderwall - Guitar Version.mp3", instrument: "guitar" },
    { title: "You're Beautiful", file: "Andy Angel - You're Beautiful - Guitar Version.mp3", instrument: "guitar" },
    { title: "Your Song", file: "Andy Angel - Your Song - Guitar Version.mp3", instrument: "guitar" },
    { title: "Impossible", file: "Bourbon Pine - Impossible - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Shallow", file: "Bourbon Pine - Shallow - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Until I Found You", file: "Bourbon Pine - Until I Found You - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Where Are You Now", file: "Bourbon Pine - Where Are You Now - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Angels Like You", file: "JOHANNA - Angels Like You - Guitar Version.mp3", instrument: "guitar" },
    { title: "Be My Mistake", file: "JOHANNA - Be My Mistake - Guitar Version.mp3", instrument: "guitar" },
    { title: "Cruel Summer", file: "JOHANNA - Cruel Summer - Guitar Version.mp3", instrument: "guitar" },
    { title: "Easy On Me", file: "JOHANNA - Easy On Me - Guitar Version.mp3", instrument: "guitar" },
    { title: "Here Comes The Sun", file: "JOHANNA - Here Comes The Sun - Guitar Version.mp3", instrument: "guitar" },
    { title: "I Ain't Worried", file: "JOHANNA - I Ain't Worried - Guitar Version.mp3", instrument: "guitar" },
    { title: "I Feel It Coming", file: "JOHANNA - I Feel It Coming - Guitar Version.mp3", instrument: "guitar" },
    { title: "Let It Go", file: "JOHANNA - Let It Go - Guitar Version.mp3", instrument: "guitar" },
    { title: "Matilda", file: "JOHANNA - Matilda - Guitar Version.mp3", instrument: "guitar" },
    { title: "Paradise", file: "JOHANNA - Paradise - Guitar Version.mp3", instrument: "guitar" },
    { title: "Something Just Like This", file: "JOHANNA - Something Just Like This - Guitar Version.mp3", instrument: "guitar" },
    { title: "Viva La Vida", file: "JOHANNA - Viva La Vida - Guitar Version.mp3", instrument: "guitar" },
    { title: "When We Were Young", file: "JOHANNA - When We Were Young - Guitar Version.mp3", instrument: "guitar" },
    { title: "driver's license", file: "JOHANNA - driver's licence - Guitar Version.mp3", instrument: "guitar" },
    { title: "everything i wanted", file: "JOHANNA - everything i wanted - Guitar Version.mp3", instrument: "guitar" },
    { title: "Baba O'Riley", file: "Johan KvarnstrÃ¶m - Baba O'Riley - Guitar Version.mp3", instrument: "guitar" },
    { title: "Blackbird", file: "Johan KvarnstrÃ¶m - Blackbird - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Cold Water", file: "Johan KvarnstrÃ¶m - Cold Water - Guitar Version.mp3", instrument: "guitar" },
    { title: "Could You Be Loved", file: "Johan KvarnstrÃ¶m - Could You Be Loved - Guitar Version.mp3", instrument: "guitar" },
    { title: "Euphoria", file: "Johan KvarnstrÃ¶m - Euphoria - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Heartbeats", file: "Johan KvarnstrÃ¶m - Heartbeats - Guitar Version.mp3", instrument: "guitar" },
    { title: "Lay All Your Love On Me", file: "Johan KvarnstrÃ¶m - Lay All Your Love On Me - Guitar Version.mp3", instrument: "guitar" },
    { title: "Pumped Up Kicks", file: "Johan KvarnstrÃ¶m - Pumped Up Kicks - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Rolling in the Deep", file: "Johan KvarnstrÃ¶m - Rolling in the Deep - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Say My Name", file: "Johan KvarnstrÃ¶m - Say My Name - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Smoke On the Water", file: "Johan KvarnstrÃ¶m - Smoke On the Water - Guitar Version.mp3", instrument: "guitar" },
    { title: "Stairway to Heaven", file: "Johan KvarnstrÃ¶m - Stairway to Heaven - Guitar Version.mp3", instrument: "guitar" },
    { title: "Summer of '69", file: "Johan KvarnstrÃ¶m - Summer of '69 - Guitar Version.mp3", instrument: "guitar" },
    { title: "Take Me Home, Country Roads", file: "Johan KvarnstrÃ¶m - Take Me Home, Country Roads - Guitar Version.mp3", instrument: "guitar" },
    { title: "Teenage Dirtbag", file: "Johan KvarnstrÃ¶m - Teenage Dirtbag - Guitar Cover.mp3", instrument: "guitar" },
    { title: "good 4 u", file: "Johan KvarnstrÃ¶m - good 4 u - Guitar Version.mp3", instrument: "guitar" },
    { title: "All Night Long", file: "Mount Hayes - All Night Long - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Another Day in Paradise", file: "Mount Hayes - Another Day in Paradise - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Blue (Da Ba Dee)", file: "Mount Hayes - Blue (Da Ba Dee) - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Chasing Cars", file: "Mount Hayes - Chasing Cars - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Chemical", file: "Mount Hayes - Chemical - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Congratulations", file: "Mount Hayes - Congratulations - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Crosses", file: "Mount Hayes - Crosses - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Everlong", file: "Mount Hayes - Everlong - Guitar Version.mp3", instrument: "guitar" },
    { title: "Flowers", file: "Mount Hayes - Flowers - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Heaven", file: "Mount Hayes - Heaven - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Hold On, We're Going Home", file: "Mount Hayes - Hold On, We're Going Home - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "I Can't Feel My Face", file: "Mount Hayes - I Can't Feel My Face - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "I'm On Fire", file: "Mount Hayes - I'm On Fire - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "I'm Yours", file: "Mount Hayes - I'm Yours - Guitar Version.mp3", instrument: "guitar" },
    { title: "Into You", file: "Mount Hayes - Into You - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Isn't She Lovely", file: "Mount Hayes - Isn't She Lovely - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Lose You to Love Me", file: "Mount Hayes - Lose You to Love Me - Acoustic Cover.mp3", instrument: "guitar" },
    { title: "Mad World", file: "Mount Hayes - Mad World - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Magic", file: "Mount Hayes - Magic - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Numb", file: "Mount Hayes - Numb - Guitar Cover.mp3", instrument: "guitar" },
    { title: "One Last Time", file: "Mount Hayes - One Last Time - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Purple Rain", file: "Mount Hayes - Purple Rain - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Ring of Fire", file: "Mount Hayes - Ring of Fire - Guitar Cover.mp3", instrument: "guitar" },
    { title: "STAY", file: "Mount Hayes - STAY - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Say You Wont Let Go", file: "Mount Hayes - Say You Wont Let Go - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Scar Tissue", file: "Mount Hayes - Scar Tissue - Guitar Version.mp3", instrument: "guitar" },
    { title: "SeÃ±orita", file: "Mount Hayes - SeÃ±orita - Acoustic Guitar Version.mp3", instrument: "guitar" },
    { title: "Sex on Fire", file: "Mount Hayes - Sex on Fire - Guitar Version.mp3", instrument: "guitar" },
    { title: "Skinny Love", file: "Mount Hayes - Skinny Love - Guitar Version.mp3", instrument: "guitar" },
    { title: "Snow (Hey Ho)", file: "Mount Hayes - Snow (Hey Ho) - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Sorry", file: "Mount Hayes - Sorry - Guitar Version.mp3", instrument: "guitar" },
    { title: "Still Haven't Found What I'm Looking For", file: "Mount Hayes - Still Haven't Found What I'm Looking For - Guitar Version.mp3", instrument: "guitar" },
    { title: "Style", file: "Mount Hayes - Style - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Sweet Child o' Mine", file: "Mount Hayes - Sweet Child o' Mine - Acoustic Guitar Version.mp3", instrument: "guitar" },
    { title: "Sweet Home Alabama", file: "Mount Hayes - Sweet Home Alabama - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Teenage Dream", file: "Mount Hayes - Teenage Dream - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Thinking Out Loud", file: "Mount Hayes - Thinking Out Loud - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Time After Time", file: "Mount Hayes - Time After Time - Guitar Cover.mp3", instrument: "guitar" },
    { title: "Under the Bridge", file: "Mount Hayes - Under the Bridge - Guitar Version.mp3", instrument: "guitar" },
    { title: "Use Somebody", file: "Mount Hayes - Use Somebody - Acoustic Guitar Version.mp3", instrument: "guitar" },
    { title: "Watermelon Sugar", file: "Mount Hayes - Watermelon Sugar - Acoustic Version.mp3", instrument: "guitar" },
    { title: "Yellow", file: "Mount Hayes - Yellow - Acoustic Guitar Version.mp3", instrument: "guitar" },
    { title: "Zombie", file: "Mount Hayes - Zombie - Acoustic Version.mp3", instrument: "guitar" },
    { title: "a thousand years", file: "guitar girl - a thousand years - guitar version.mp3", instrument: "guitar" },
    { title: "always you", file: "guitar girl - always you - guitar version.mp3", instrument: "guitar" },
    { title: "arcade", file: "guitar girl - arcade - guitar version.mp3", instrument: "guitar" },
    { title: "at my worst", file: "guitar girl - at my worst - guitar version.mp3", instrument: "guitar" },
    { title: "better", file: "guitar girl - better - guitar version.mp3", instrument: "guitar" },
    { title: "build me up buttercup", file: "guitar girl - build me up buttercup - guitar version.mp3", instrument: "guitar" },
    { title: "butter", file: "guitar girl - butter - guitar version.mp3", instrument: "guitar" },
    { title: "chasing cars", file: "guitar girl - chasing cars - guitar version.mp3", instrument: "guitar" },
    { title: "coffee", file: "guitar girl - coffee - guitar version.mp3", instrument: "guitar" },
    { title: "corduroy dreams", file: "guitar girl - corduroy dreams - guitar version.mp3", instrument: "guitar" },
    { title: "counting stars", file: "guitar girl - counting stars - guitar version.mp3", instrument: "guitar" },
    { title: "dandelions", file: "guitar girl - dandelions - guitar version.mp3", instrument: "guitar" },
    { title: "dark horse", file: "guitar girl - dark horse - guitar version.mp3", instrument: "guitar" },
    { title: "demons", file: "guitar girl - demons - guitar version.mp3", instrument: "guitar" },
    { title: "ditto", file: "guitar girl - ditto - guitar version.mp3", instrument: "guitar" },
    { title: "double take", file: "guitar girl - double take - guitar version.mp3", instrument: "guitar" },
    { title: "eenie meenie", file: "guitar girl - eenie meenie - guitar version.mp3", instrument: "guitar" },
    { title: "everything i wanted", file: "guitar girl - everything i wanted - guitar version.mp3", instrument: "guitar" },
    { title: "eyes blue like the atlantic", file: "guitar girl - eyes blue like the atlantic - guitar version.mp3", instrument: "guitar" },
    { title: "glimpse of us", file: "guitar girl - glimpse of us - guitar version.mp3", instrument: "guitar" },
    { title: "heather", file: "guitar girl - heather - guitar version.mp3", instrument: "guitar" },
    { title: "here with me", file: "guitar girl - here with me - guitar version.mp3", instrument: "guitar" },
    { title: "hey, soul sister", file: "guitar girl - hey, soul sister - guitar version.mp3", instrument: "guitar" },
    { title: "i.f.l.y.", file: "guitar girl - i.f.l.y. - guitar version.mp3", instrument: "guitar" },
    { title: "ice cream", file: "guitar girl - ice cream - guitar version.mp3", instrument: "guitar" },
    { title: "imagination", file: "guitar girl - imagination - guitar version.mp3", instrument: "guitar" },
    { title: "it will rain", file: "guitar girl - it will rain - guitar version.mp3", instrument: "guitar" },
    { title: "jocelyn flores", file: "guitar girl - jocelyn flores - guitar version.mp3", instrument: "guitar" },
    { title: "just the two of us", file: "guitar girl - just the two of us - guitar version.mp3", instrument: "guitar" },
    { title: "kiss me more", file: "guitar girl - kiss me more - guitar version.mp3", instrument: "guitar" },
    { title: "last christmas", file: "guitar girl - last christmas - guitar version.mp3", instrument: "guitar" },
    { title: "like i can", file: "guitar girl - like i can - guitar version.mp3", instrument: "guitar" },
    { title: "line without a hook", file: "guitar girl - line without a hook - guitar version.mp3", instrument: "guitar" },
    { title: "love is gone", file: "guitar girl - love is gone - guitar version.mp3", instrument: "guitar" },
    { title: "mad at disney", file: "guitar girl - mad at disney - guitar version.mp3", instrument: "guitar" },
    { title: "mockingbird", file: "guitar girl - mockingbird - guitar version.mp3", instrument: "guitar" },
    { title: "my boo", file: "guitar girl - my boo - guitar version.mp3", instrument: "guitar" },
    { title: "my love mine all mine", file: "guitar girl - my love mine all mine - guitar version.mp3", instrument: "guitar" },
    { title: "night changes", file: "guitar girl - night changes - guitar version.mp3", instrument: "guitar" },
    { title: "ocean eyes", file: "guitar girl - ocean eyes - guitar version.mp3", instrument: "guitar" },
    { title: "polaroid love", file: "guitar girl - polaroid love - guitar version.mp3", instrument: "guitar" },
    { title: "pompeii", file: "guitar girl - pompeii - guitar version.mp3", instrument: "guitar" },
    { title: "rewrite the stars", file: "guitar girl - rewrite the stars - guitar version.mp3", instrument: "guitar" },
    { title: "save your tears", file: "guitar girl - save your tears - guitar version.mp3", instrument: "guitar" },
    { title: "slow dancing in the dark", file: "guitar girl - slow dancing in the dark - guitar version.mp3", instrument: "guitar" },
    { title: "stay with me", file: "guitar girl - stay with me - guitar version.mp3", instrument: "guitar" },
    { title: "stereo hearts", file: "guitar girl - stereo hearts - guitar version.mp3", instrument: "guitar" },
    { title: "still into you", file: "guitar girl - still into you - guitar version.mp3", instrument: "guitar" },
    { title: "still with you", file: "guitar girl - still with you - guitar version.mp3", instrument: "guitar" },
    { title: "sweet home alabama", file: "guitar girl - sweet home alabama - guitar version.mp3", instrument: "guitar" },
    { title: "take me to church", file: "guitar girl - take me to church - guitar version.mp3", instrument: "guitar" },
    { title: "talking to the moon", file: "guitar girl - talking to the moon - guitar version.mp3", instrument: "guitar" },
    { title: "the remedy for a broken heart (why am i so in love)", file: "guitar girl - the remedy for a broken heart (why am i so in love) - guitar version.mp3", instrument: "guitar" },
    { title: "the scientist", file: "guitar girl - the scientist - guitar version.mp3", instrument: "guitar" },
    { title: "to the bone", file: "guitar girl - to the bone - guitar version.mp3", instrument: "guitar" },
    { title: "tonight you belong to me", file: "guitar girl - tonight you belong to me - guitar version.mp3", instrument: "guitar" },
    { title: "until i found you", file: "guitar girl - until i found you - guitar version.mp3", instrument: "guitar" },

    // Harp
    { title: "Norwegian Lullaby", file: "Harper Tasche - Norwegian Lullaby.mp3", instrument: "harp" },

    // Piano
    { title: "Ellie", file: "Aeyes Libe - Ellie.mp3", instrument: "piano" },
    { title: "Once", file: "Alexis Ffrench - Once.mp3", instrument: "piano" },
    { title: "land in sight", file: "Alfons Daiminger - land in sight.mp3", instrument: "piano" },
    { title: "Full Bloom", file: "Allysium - Full Bloom.mp3", instrument: "piano" },
    { title: "This Tender Appeal", file: "Allysium - This Tender Appeal.mp3", instrument: "piano" },
    { title: "Forevermore", file: "Ambrosiae - Forevermore.mp3", instrument: "piano" },
    { title: "Cascade", file: "AndrÃ© DÃ­vou - Cascade.mp3", instrument: "piano" },
    { title: "Before You", file: "Andrea Prine - Before You.mp3", instrument: "piano" },
    { title: "Pine Scent", file: "Anthony Sullivan - Pine Scent.mp3", instrument: "piano" },
    { title: "Miles and Miles", file: "Antoine Di Luzio - Miles and Miles.mp3", instrument: "piano" },
    { title: "The Story of Forever", file: "Aquilegia - The Story of Forever.mp3", instrument: "piano" },
    { title: "A Safe Space", file: "Aramis Morel - A Safe Space.mp3", instrument: "piano" },
    { title: "Endlich", file: "Arata Rin - Endlich.mp3", instrument: "piano" },
    { title: "Diaphanous", file: "Arden Brooks - Diaphanous.mp3", instrument: "piano" },
    { title: "Afterlight", file: "Arlo Thiem - Afterlight.mp3", instrument: "piano" },
    { title: "The Swan", file: "Ava Cerasoli - The swan.mp3", instrument: "piano" },
    { title: "Morning Dew", file: "Blue George - Morning Dew.mp3", instrument: "piano" },
    { title: "Fiume", file: "Carluccio - Fiume.mp3", instrument: "piano" },
    { title: "A quiet river", file: "Christian Janssen - A quiet river.mp3", instrument: "piano" },
    { title: "Hearts of Desire", file: "Cole Neumann - Hearts of Desire.mp3", instrument: "piano" },
    { title: "Archipelago", file: "Damien Jeunet - Archipelago.mp3", instrument: "piano" },
    { title: "In Presence", file: "Dean Demir - In Presence.mp3", instrument: "piano" },
    { title: "Wish", file: "Domi Nova - Wish.mp3", instrument: "piano" },
    { title: "Solace", file: "Dunham Froebel - Solace.mp3", instrument: "piano" },
    { title: "Window Seat", file: "Dwight Rivera - Window Seat.mp3", instrument: "piano" },
    { title: "Lazy Sunday Vibes", file: "Erjos - Lazy Sunday Vibes.mp3", instrument: "piano" },
    { title: "Valse Naturelle", file: "Etienne Balestre - Valse Naturelle.mp3", instrument: "piano" },
    { title: "Fjord", file: "EyÃ¸ni - Fjord.mp3", instrument: "piano" },
    { title: "Fino Mornasco", file: "Fabrizio Gallo - Fino Mornasco.mp3", instrument: "piano" },
    { title: "Waitings", file: "Felix Tanguy - Waitings.mp3", instrument: "piano" },
    { title: "A New Start", file: "Ferragno - A New Start.mp3", instrument: "piano" },
    { title: "Notturno", file: "Finja Seiden - Notturno.mp3", instrument: "piano" },
    { title: "Desire paths", file: "Finn Lenoir - Desire paths.mp3", instrument: "piano" },
    { title: "Seasons Flowing", file: "Fleur Bloom - Seasons Flowing.mp3", instrument: "piano" },
    { title: "Almost April", file: "Florentin Davies - Almost April.mp3", instrument: "piano" },
    { title: "Eridani", file: "Flori Vivea - Eridani.mp3", instrument: "piano" },
    { title: "Nostalgia", file: "Floyd Nova - Nostalgia.mp3", instrument: "piano" },
    { title: "Sanctuary", file: "Floyd Nova - Sanctuary.mp3", instrument: "piano" },
    { title: "Contemplazione del Mare", file: "Gianni Domenici - Contemplazione del Mare.mp3", instrument: "piano" },
    { title: "For Asher", file: "Glenn Natale - For Asher.mp3", instrument: "piano" },
    { title: "For A Moment", file: "Haley Morrison - For A Moment.mp3", instrument: "piano" },
    { title: "The Proposal", file: "Henriette Fleich - The Proposal.mp3", instrument: "piano" },
    { title: "190306-11", file: "Hideyuki Hashimoto - 190306-11.mp3", instrument: "piano" },
    { title: "Like an Open Book", file: "Hudson Rull - Like an Open Book.mp3", instrument: "piano" },
    { title: "Grand Canyon", file: "Ivan Vladusic - Grand Canyon.mp3", instrument: "piano" },
    { title: "soft life", file: "Jack Ame - soft life.mp3", instrument: "piano" },
    { title: "The Coming Day", file: "Jack Pelayo - The Coming Day.mp3", instrument: "piano" },
    { title: "Glance Out A Casement Window", file: "Janet Redger - Glance Out A Casement Window.mp3", instrument: "piano" },
    { title: "Under a tree", file: "Janet Redger - Under a tree.mp3", instrument: "piano" },
    { title: "Countryside Mansion", file: "Joanne Gardiner - Countryside Mansion.mp3", instrument: "piano" },
    { title: "Where the Rivers Run", file: "Joaquim Azevedo - Where the Rivers Run.mp3", instrument: "piano" },
    { title: "Peace flow", file: "Jon Albreit - Peace flow.mp3", instrument: "piano" },
    { title: "Biassono", file: "Joviano Carrera - Biassono.mp3", instrument: "piano" },
    { title: "Elegie", file: "Kiara Leonard - Elegie.mp3", instrument: "piano" },
    { title: "Come close", file: "Korey Banks - Come close.mp3", instrument: "piano" },
    { title: "Dalmine", file: "Lakes of Lagan - Dalmine.mp3", instrument: "piano" },
    { title: "Future æœªæ¥", file: "Li Zemin - Future æœªæ¥.mp3", instrument: "piano" },
    { title: "Bona Fide", file: "Linn Hope - Bona Fide.mp3", instrument: "piano" },
    { title: "to be better again", file: "Lonesome Mind - to be better again.mp3", instrument: "piano" },
    { title: "Arabesco", file: "Lorenzo Fiore - Arabesco.mp3", instrument: "piano" },
    { title: "Adieux", file: "Ludovico Einaudi - Adieux.mp3", instrument: "piano" },
    { title: "A tale to tell", file: "Luiza Schmidt - A tale to tell.mp3", instrument: "piano" },
    { title: "I Believe", file: "M. LjungstrÃ¶m - I Believe.mp3", instrument: "piano" },
    { title: "Quietly Into The Night", file: "M. LjungstrÃ¶m - Quietly Into The Night.mp3", instrument: "piano" },
    { title: "Sound Of Mind", file: "Macan Moreira - Sound Of Mind.mp3", instrument: "piano" },
    { title: "Nordanvind", file: "Malmkvist - Nordanvind.mp3", instrument: "piano" },
    { title: "Falling Rain", file: "Matthew L. Fisher - Falling Rain.mp3", instrument: "piano" },
    { title: "Schutter", file: "May Laude - Schutter.mp3", instrument: "piano" },
    { title: "floating points", file: "May Laude - floating points.mp3", instrument: "piano" },
    { title: "Fall a hundred times", file: "Maya Sarfatto - Fall a hundred times.mp3", instrument: "piano" },
    { title: "Humanity", file: "MelaniÃ© Poret - Humanity.mp3", instrument: "piano" },
    { title: "Nouveau DÃ©part", file: "Mes Croquis - Nouveau DÃ©part.mp3", instrument: "piano" },
    { title: "Veiled Remembrance", file: "Mijongya - Veiled Remembrance.mp3", instrument: "piano" },
    { title: "Inmaterial color", file: "Mima Renard - Inmaterial color.mp3", instrument: "piano" },
    { title: "I Dare Not Dream- Fantasia on a Theme", file: "MÃ­rabilis - I Dare Not Dream- Fantasia on a Theme.mp3", instrument: "piano" },
    { title: "Misterbianco", file: "Mireia Lluna - Misterbianco.mp3", instrument: "piano" },
    { title: "Luminous", file: "Moldoveanu - Luminous.mp3", instrument: "piano" },
    { title: "between the mountains", file: "Nadav Cohen - between the mountains.mp3", instrument: "piano" },
    { title: "Beauty", file: "Nascondiglio - Beauty.mp3", instrument: "piano" },
    { title: "Prato Di Girasoli", file: "Nascondiglio - Prato Di Girasoli.mp3", instrument: "piano" },
    { title: "Valse Delle Vigne", file: "Nascondiglio - Valse Delle Vigne.mp3", instrument: "piano" },
    { title: "Beginnings", file: "NÃ©er - Beginnings.mp3", instrument: "piano" },
    { title: "Mavro", file: "Niamh Shona - Mavro.mp3", instrument: "piano" },
    { title: "Daydreamer", file: "Noah Hart - Daydreamer.mp3", instrument: "piano" },
    { title: "Dreamt, far hope", file: "North Connolly - Dreamt, far hope....mp3", instrument: "piano" },
    { title: "Whispering Oaks", file: "Olivier DeGrasse - Whispering Oaks.mp3", instrument: "piano" },
    { title: "My Story", file: "Portia Price - My Story.mp3", instrument: "piano" },
    { title: "Fields Of Love", file: "Reinhard Krause - Fields Of Love.mp3", instrument: "piano" },
    { title: "Onward", file: "Remi Sinclair - Onward.mp3", instrument: "piano" },
    { title: "Aspres", file: "Ren Lockhart - Aspres.mp3", instrument: "piano" },
    { title: "Cataverdis", file: "Ren Lockhart - Cataverdis.mp3", instrument: "piano" },
    { title: "Spring Roses", file: "Ren Lockhart - Spring Roses.mp3", instrument: "piano" },
    { title: "Almenno San Salvatore", file: "Rhian Cadogan - Almenno San Salvatore.mp3", instrument: "piano" },
    { title: "The Breeze and The Wildflower", file: "Roasting Coffee Beans - The Breeze and The Wildflower.mp3", instrument: "piano" },
    { title: "Grounded in Love", file: "Rocco - Grounded in Love.mp3", instrument: "piano" },
    { title: "Muse Of History", file: "Roland Toubin - Muse Of History.mp3", instrument: "piano" },
    { title: "To Bring Rest", file: "Sabrina Austin - To Bring Rest.mp3", instrument: "piano" },
    { title: "Arbor", file: "Samuel Kees - Arbor.mp3", instrument: "piano" },
    { title: "The Old Garden", file: "Sanna Aalto - The Old Garden.mp3", instrument: "piano" },
    { title: "Cavaria", file: "Shea Ada - Cavaria.mp3", instrument: "piano" },
    { title: "Paths", file: "Silje MÃ¸rk - Paths.mp3", instrument: "piano" },
    { title: "Astray", file: "Simon Vouet - Astray.mp3", instrument: "piano" },
    { title: "Sketch of an Oak", file: "Stanley Lloyd - Sketch of an Oak.mp3", instrument: "piano" },
    { title: "Communion", file: "Stephan Moccio - Communion.mp3", instrument: "piano" },
    { title: "Dancing Dreams", file: "Steven Windhaug - Dancing Dreams.mp3", instrument: "piano" },
    { title: "Deja", file: "Taganema - Deja.mp3", instrument: "piano" },
    { title: "Moving on", file: "Tankeflukt - Moving on.mp3", instrument: "piano" },
    { title: "Just Stay", file: "Tristaan Wilkes - Just Stay.mp3", instrument: "piano" },
    { title: "Elegy Of Solace", file: "Una Luna - Elegy Of Solace.mp3", instrument: "piano" },
    { title: "To New Beginnings", file: "Una Luna - To New Beginnings.mp3", instrument: "piano" },
    { title: "Resta Con Me", file: "Valentina Romano - Resta Con Me.mp3", instrument: "piano" },
    { title: "Other Side of The Moon", file: "ValÃ©rie ValÃ©rie - Other Side of The Moon.mp3", instrument: "piano" },
    { title: "Brume Matinale", file: "Valter Soll - Brume Matinale.mp3", instrument: "piano" },
    { title: "Autumn Sky", file: "Vincent Fanguro - Autumn Sky.mp3", instrument: "piano" },
    { title: "The Way Home", file: "Wendie Solomon - The Way Home.mp3", instrument: "piano" },
    { title: "Serene", file: "Will Kaufman - Serene.mp3", instrument: "piano" },
    { title: "Air from Air", file: "Yasuo Zen - Air from Air.mp3", instrument: "piano" },
    { title: "frozen kiss", file: "everall - frozen kiss.mp3", instrument: "piano" },
    { title: "nocturnal summer", file: "forever day - nocturnal summer.mp3", instrument: "piano" },

    // Saxophone
    { title: "Can't Catch Me Now", file: "Avromi Basch - Can't Catch Me Now - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Cruel Summer", file: "Avromi Basch - Cruel Summer - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Greedy", file: "Avromi Basch - Greedy - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Houdini", file: "Avromi Basch - Houdini - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Imagine", file: "Avromi Basch - Imagine - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Levitating", file: "Avromi Basch - Levitating - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Lipstick", file: "Avromi Basch - Lipstick - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Selfish", file: "Avromi Basch - Selfish - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Stand By Me", file: "Avromi Basch - Stand By Me - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Texas Hold 'Em", file: "Avromi Basch - Texas Hold 'Em - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Training Season", file: "Avromi Basch - Training Season - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "A Little Bit Yours", file: "Bobby G - A Little Bit Yours - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "A Thousand Miles", file: "Bobby G - A Thousand Miles - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Always Be My Baby", file: "Bobby G - Always Be My Baby - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Anyone", file: "Bobby G - Anyone.mp3", instrument: "saxophone" },
    { title: "Back To December", file: "Bobby G - Back To December - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Bad Day", file: "Bobby G - Bad Day - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Better Together", file: "Bobby G - Better Together - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Catch My Breath", file: "Bobby G - Catch My Breath - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Curtains", file: "Bobby G - Curtains - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Daughters", file: "Bobby G - Daughters - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Dive", file: "Bobby G - Dive - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Dreaming with a Broken Heart", file: "Bobby G - Dreaming with a Broken Heart - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Enchanted", file: "Bobby G - Enchanted - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Fearless", file: "Bobby G - Fearless - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Fireflies", file: "Bobby G - Fireflies - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Firework", file: "Bobby G - Firework - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Flowers", file: "Bobby G - Flowers - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Forget Me", file: "Bobby G - Forget Me - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Good Riddance (Time of Your Life)", file: "Bobby G - Good Riddance (Time of Your Life) - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Havana", file: "Bobby G - Havana - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Hey There Delilah", file: "Bobby G - Hey There Delilah - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "I Knew You Were Trouble", file: "Bobby G - I Knew You Were Trouble. - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "I Want It That Way", file: "Bobby G - I Want It That Way - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "I Will Follow You into the Dark", file: "Bobby G - I Will Follow You into the Dark - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "I'll Be Waiting", file: "Bobby G - I'll Be Waiting - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "I'll Make Love To You", file: "Bobby G - I'll Make Love To You - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "If I Ain't Got You", file: "Bobby G - If I Ain't Got You - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "It Wasn't Me", file: "Bobby G - It Wasn't Me - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Just the Way You Are", file: "Bobby G - Just the Way You Are - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Love On The Brain", file: "Bobby G - Love On The Brain - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Material Girl", file: "Bobby G - Material Girl - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Meltdown", file: "Bobby G - Meltdown - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Mercy", file: "Bobby G - Mercy - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "My Love Mine All Mine", file: "Bobby G - My Love Mine All Mine - Saxophone Instrumental.mp3", instrument: "saxophone" },
    { title: "Need You Now", file: "Bobby G - Need You Now - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "No Scrubs", file: "Bobby G - No Scrubs - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Oops!...I Did It Again", file: "Bobby G - Oops!...I Did It Again - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Over My Head", file: "Bobby G - Over My Head - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Perfect", file: "Bobby G - Perfect - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Perfectly Imperfect", file: "Bobby G - Perfectly Imperfect - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Rainbow", file: "Bobby G - Rainbow - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Someone You Loved", file: "Bobby G - Someone You Loved - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Stand By Me", file: "Bobby G - Stand By Me - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Stick Season", file: "Bobby G - Stick Season - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Toxic", file: "Bobby G - Toxic - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Waterfalls", file: "Bobby G - Waterfalls - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "What Was I Made For", file: "Bobby G - What Was I Made For - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "When You Look Me In The Eyes", file: "Bobby G - When You Look Me In The Eyes - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Wildest Dreams", file: "Bobby G - Wildest Dreams - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Wish You The Best", file: "Bobby G - Wish You The Best - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Wonderwall", file: "Bobby G - Wonderwall - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "You Found Me", file: "Bobby G - You Found Me - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "ceilings", file: "Bobby G - ceilings - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "drivers license", file: "Bobby G - drivers license.mp3", instrument: "saxophone" },
    { title: "favorite crime", file: "Bobby G - favorite crime - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "logical", file: "Bobby G - logical - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Chemical", file: "Bobby G, Brendan Ross - Chemical - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Snooze", file: "Bobby G, Evan Jacobson - Snooze - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Used To Be Young", file: "Edward Ong, Bobby G - Used To Be Young - Saxophone and Guitar Instrumental.mp3", instrument: "saxophone" },
    { title: "Fix You", file: "Milo Grande - Fix You - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "See You Again", file: "Milo Grande - See You Again - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Thinking out Loud", file: "Milo Grande - Thinking out Loud - Saxophone Version.mp3", instrument: "saxophone" },
    { title: "Every Breath You Take", file: "Saxophone Rufus - Every Breath You Take.mp3", instrument: "saxophone" },
    { title: "Love Never Felt So Good", file: "Saxophone Rufus - Love Never Felt So Good.mp3", instrument: "saxophone" },

    // Violin
    { title: "All Of Me", file: "Alison Sparrow - All Of Me Violin Cover.mp3", instrument: "violin" },
    { title: "Halo", file: "Caleb Chan, Brian Chan - Halo.mp3", instrument: "violin" },
    { title: "Nobody Gets Me", file: "Caleb Chan, Brian Chan - Nobody Gets Me.mp3", instrument: "violin" },
    { title: "Run the World", file: "Caleb Chan, Brian Chan - Run the World.mp3", instrument: "violin" },
    { title: "Viva La Vida", file: "David Garrett - Viva La Vida.mp3", instrument: "violin" },
    { title: "What About Us", file: "Duomo - What About Us.mp3", instrument: "violin" },
    { title: "Wildest Dreams", file: "Duomo - Wildest Dreams.mp3", instrument: "violin" },
    { title: "You Oughta Know", file: "Duomo, TomÃ¡s Peire-Serrate - You Oughta Know.mp3", instrument: "violin" },
    { title: "As It Was", file: "Ground Zero Academy Orchestra - As It Was.mp3", instrument: "violin" },
    { title: "Bloody Mary", file: "Ground Zero Academy Orchestra - Bloody Mary.mp3", instrument: "violin" },
    { title: "Concerning Hobbits", file: "Ground Zero Academy Orchestra - Concerning Hobbits.mp3", instrument: "violin" },
    { title: "Daylight", file: "Ground Zero Academy Orchestra - Daylight.mp3", instrument: "violin" },
    { title: "Demons", file: "Ground Zero Academy Orchestra - Demons.mp3", instrument: "violin" },
    { title: "Flowers", file: "Ground Zero Academy Orchestra - Flowers.mp3", instrument: "violin" },
    { title: "Golden Hour", file: "Ground Zero Academy Orchestra - Golden Hour.mp3", instrument: "violin" },
    { title: "Lift Me Up", file: "Ground Zero Academy Orchestra - Lift Me Up.mp3", instrument: "violin" },
    { title: "Memories", file: "Ground Zero Academy Orchestra - Memories.mp3", instrument: "violin" },
    { title: "O Holy Night", file: "Ground Zero Academy Orchestra - O Holy Night.mp3", instrument: "violin" },
    { title: "Perfect", file: "Ground Zero Academy Orchestra - Perfect.mp3", instrument: "violin" },
    { title: "Running Up That Hill", file: "Ground Zero Academy Orchestra - Running Up That Hill.mp3", instrument: "violin" },
    { title: "Unholy", file: "Ground Zero Academy Orchestra - Unholy.mp3", instrument: "violin" },
    { title: "Yellow", file: "Ground Zero Academy Orchestra - Yellow.mp3", instrument: "violin" },
    { title: "Diamonds", file: "Hannah V, Joe Rodwell - Diamonds.mp3", instrument: "violin" },
    { title: "Arcade", file: "ItsAMoney - Arcade - Violin Version.mp3", instrument: "violin" },
    { title: "Lovely", file: "ItsAMoney - Lovely.mp3", instrument: "violin" },
    { title: "Perfect", file: "Karolina Protsenko - Perfect.mp3", instrument: "violin" },
    { title: "Rockabye", file: "Karolina Protsenko - Rockabye.mp3", instrument: "violin" },
    { title: "Bad Guy", file: "Kezia Amelia - Bad Guy.mp3", instrument: "violin" },
    { title: "Material Girl", file: "Kris Bowers - Material Girl.mp3", instrument: "violin" },
    { title: "Strange", file: "Kris Bowers - Strange (feat. Hillary Smith).mp3", instrument: "violin" },
    { title: "A Feeling I've Never Been", file: "Kris Bowers, Tayla Parx - A Feeling I've Never Been.mp3", instrument: "violin" },
    { title: "Wrecking Ball", file: "Midnite String Quartet - Wrecking Ball.mp3", instrument: "violin" },
    { title: "Deja Vu", file: "Mike Froudarakis, Alexander Leeming Froudakis - Deja Vu.mp3", instrument: "violin" },
    { title: "Havana", file: "Robert Mendoza - Havana.mp3", instrument: "violin" },
    { title: "Sign of the Times", file: "Steve Horner - Sign of the Times - Stripped.mp3", instrument: "violin" },
    { title: "Anti-Hero", file: "Vitamin String Quartet - Anti-Hero.mp3", instrument: "violin" },
    { title: "As It Was", file: "Vitamin String Quartet - As It Was.mp3", instrument: "violin" },
    { title: "Blinding Lights", file: "Vitamin String Quartet - Blinding Lights.mp3", instrument: "violin" },
    { title: "Butter", file: "Vitamin String Quartet - Butter.mp3", instrument: "violin" },
    { title: "Chandelier", file: "Vitamin String Quartet - Chandelier.mp3", instrument: "violin" },
    { title: "Dancing On My Own", file: "Vitamin String Quartet - Dancing On My Own.mp3", instrument: "violin" },
    { title: "Donâ€™t Start Now", file: "Vitamin String Quartet - Donâ€™t Start Now.mp3", instrument: "violin" },
    { title: "Easy On Me", file: "Vitamin String Quartet - Easy On Me.mp3", instrument: "violin" },
    { title: "Girls Like You", file: "Vitamin String Quartet - Girls Like You.mp3", instrument: "violin" },
    { title: "I Will Always Love You", file: "Vitamin String Quartet - I Will Always Love You.mp3", instrument: "violin" },
    { title: "If I Ain't Got You", file: "Vitamin String Quartet - If I Ain't Got You.mp3", instrument: "violin" },
    { title: "In My Blood", file: "Vitamin String Quartet - In My Blood.mp3", instrument: "violin" },
    { title: "Kiss Me More", file: "Vitamin String Quartet - Kiss Me More.mp3", instrument: "violin" },
    { title: "Levitating", file: "Vitamin String Quartet - Levitating.mp3", instrument: "violin" },
    { title: "Peaches", file: "Vitamin String Quartet - Peaches.mp3", instrument: "violin" },
    { title: "Rain On Me", file: "Vitamin String Quartet - Rain On Me.mp3", instrument: "violin" },
    { title: "Running Up That Hill (A Deal With God)", file: "Vitamin String Quartet - Running Up That Hill (A Deal With God).mp3", instrument: "violin" },
    { title: "Santa Tell Me", file: "Vitamin String Quartet - Santa Tell Me.mp3", instrument: "violin" },
    { title: "Stay Away", file: "Vitamin String Quartet - Stay Away.mp3", instrument: "violin" },
    { title: "Stay with Me", file: "Vitamin String Quartet - Stay with Me.mp3", instrument: "violin" },
    { title: "Watermelon Sugar", file: "Vitamin String Quartet - Watermelon Sugar.mp3", instrument: "violin" },
    { title: "bad guy", file: "Vitamin String Quartet - bad guy.mp3", instrument: "violin" },
    { title: "driver's license", file: "Vitamin String Quartet - driver's license.mp3", instrument: "violin" },
    { title: "positions", file: "Vitamin String Quartet - positions - Bonus Track.mp3", instrument: "violin" },
    { title: "thank u, next", file: "Vitamin String Quartet - thank u, next.mp3", instrument: "violin" },
    //drums
    { title: "west african drum music", file: "African Drums - West African Drum Music.mp3", instrument: "drums" },

    //cello
    { title: "Can't Help Falling in Love", file: "Cello Music - Can't Help Falling in Love.mp3", instrument: "cello" },
    { title: "Demons", file: "2CELLOS - Demons.mp3", instrument: "cello" },
    { title: "With Or Without You", file: "2CELLOS - With Or Without You.mp3", instrument: "cello" },
    { title: "Love Me Like You Do", file: "Brooklyn Duo - Love Me Like You Do.mp3", instrument: "piano, cello" },
    { title: "SeÃ±orita", file: "Simply Three - SeÃ±orita.mp3", instrument: "violin, cello, bass" },
    { title: "May it Be", file: "2CELLOS - May it Be.mp3", instrument: "cello" },
    { title: "We Found Love", file: "2CELLOS, HAUSER, Luka Sulic - We Found Love.mp3", instrument: "cello" },
    { title: "Blinding Lights", file: "Vitamin String Quartet - Blinding Lights.mp3", instrument: "string quartet" },
    { title: "Now We are Free", file: "2CELLOS - Now We are Free.mp3", instrument: "cello" },
    { title: "Blank Space", file: "Brooklyn Duo - Blank Space.mp3", instrument: "piano, cello" },
    { title: "Despacito", file: "RamÃ³n Ayala, Erika Ender, Luis Rodriguez, 2CELLOS - Despacito.mp3", instrument: "cello" },
    { title: "Chandelier", file: "Vitamin String Quartet - Chandelier.mp3", instrument: "string quartet" },
    { title: "Counting Stars", file: "Simply Three - Counting Stars.mp3", instrument: "violin, cello, bass" },
    { title: "Positions", file: "Vitamin String Quartet - positions - Bonus Track.mp3", instrument: "string quartet" },

    //flutes
    { title: "Piccolo Concertino: I. Allegro amabile", file: "Allen Stephenson, Gudrun Hinze, Leipzig Gewandhaus Orchestra, members, Henrik Hochschild - Piccolo Concertino- I. Allegro amabile.mp3", instrument: "flute" },
    { title: "Piccolo Concertino: III. Marcia - Allegretto", file: "Allen Stephenson, Gudrun Hinze, Leipzig Gewandhaus Orchestra, members, Henrik Hochschild - Piccolo Concertino- III. Marcia- Allegretto.mp3", instrument: "flute" },
    { title: "Vivaldi: Piccolo Concerto in C Major, RV 444: I. Allegro non molto", file: "Antonio Vivaldi, Jean-Pierre Rampal, I Solisti Veneti, Claudio Scimone - Vivaldi- Piccolo Concerto in C Major, RV 444- I. Allegro non molto.mp3", instrument: "flute" },
    { title: "Vivaldi: Piccolo Concerto in C Major, RV 444: II. Largo", file: "Antonio Vivaldi, Jean-Pierre Rampal, I Solisti Veneti, Claudio Scimone - Vivaldi- Piccolo Concerto in C Major, RV 444- II. Largo.mp3", instrument: "flute" },
    { title: "Vivaldi: Piccolo Concerto in C Major, RV 444: III. Allegro molto", file: "Antonio Vivaldi, Jean-Pierre Rampal, I Solisti Veneti, Claudio Scimone - Vivaldi- Piccolo Concerto in C Major, RV 444- III. Allegro molto.mp3", instrument: "flute" },
    { title: "River Flows in You", file: "Bevani - River Flows in You.mp3", instrument: "flute" },
    { title: "Nina's Jig", file: "Cullen Vance - Nina's Jig.mp3", instrument: "flute" },
    { title: "Soldier, Poet, King", file: "Cullen Vance - Soldier, Poet, King.mp3", instrument: "flute" },
    { title: "Eleanor Rigby - Arr. for Flute and Piano", file: "Ed Clarke, Chris Snelling - Eleanor Rigby - Arr. for Flute and Piano.mp3", instrument: "flute" },
    { title: "Perfect - Arr. for Flute and Piano", file: "Ed Clarke, Chris Snelling - Perfect - Arr. for Flute and Piano.mp3", instrument: "flute" },
    { title: "This Is Me - Arr. for Flute and Piano", file: "Ed Clarke, Chris Snelling - This Is Me - Arr. for Flute and Piano.mp3", instrument: "flute" },
    { title: "The Pink Panther", file: "Henry Mancini, James Galway, National Philharmonic Orchestra - The Pink Panther.mp3", instrument: "flute" },
    { title: "Beauty and the Beast", file: "Howard Ashman, Alan Menken, James Galway, Julian Lee - Beauty and the Beast.mp3", instrument: "flute" },
    { title: "My Heart Will Go On (Love Theme From 'Titanic')", file: "James Horner, Will Jennings, James Galway, Mike Mower - My Heart Will Go On (Love Theme From 'Titanic').mp3", instrument: "flute" },
    { title: "Shape of You - Cover", file: "Jhonatan Pereira Flautista - Shape of You - Cover.mp3", instrument: "flute" },
    { title: "Somewhere Only We Know - Cover", file: "Jhonatan Pereira Flautista - Somewhere Only We Know - Cover.mp3", instrument: "flute" },
    { title: "Take On Me - Cover", file: "Jhonatan Pereira Flautista - Take On Me - Cover.mp3", instrument: "flute" },
    { title: "Mystery of Love", file: "Luke Pickman - Mystery of Love.mp3", instrument: "flute" },
    { title: "Piccolo Sonata No. 4: Properly...", file: "Matt Smith, Stefan de Schepper, Peter Verhoyen - Piccolo Sonata No. 4, for Piccolo and Piano- Properly....mp3", instrument: "flute" },
    { title: "Piccolo Sonata No. 4: Seriously!!!", file: "Matt Smith, Stefan de Schepper, Peter Verhoyen - Piccolo Sonata No. 4, for Piccolo and Piano- Seriously!!!.mp3", instrument: "flute" },
    { title: "Alligator Alley", file: "Michael Daugherty, North Texas Wind Symphony, Eugene Migliaro Corporon - Alligator Alley.mp3", instrument: "flute" },
    { title: "Piccolo Sonata: I. Lively", file: "Mike Mower, Gudrun Hinze, Christiane Frucht - Piccolo Sonata- I. Lively.mp3", instrument: "flute" },
    { title: "The Tale of Tsar Saltan: The Flight of the Bumblebee", file: "Nikolai Rimsky-Korsakov, James Galway, Charles Gerhardt, National Philharmonic Orchestra - The Tale of Tsar Saltan, Op. 57- The Flight of the Bumblebee.mp3", instrument: "flute" },
    { title: "Colours of the Wind - Instrumental", file: "Pan Flute - Colours of the Wind - Instrumental.mp3", instrument: "flute" },
    { title: "March of the Belgian Paratroopers", file: "Pierre Leemans, Coastal Communities Concert Band, Don Caneva - March of the Belgian Paratroopers.mp3", instrument: "flute" },
    { title: "The Phantom of the Opera: Overture", file: "Prague Cello Quartet - The Phantom of the Opera- Overture.mp3", instrument: "flute" },
    { title: "Hokage", file: "Rustage, DizzyEight, Dreaded Yasuke, Savvy Hyuga, Connor Rapper, GameboyJones, None Like Joshua - Hokage.mp3", instrument: "flute" },
    { title: "A Hard Day's Night - Full Instrumental", file: "The Backing Tracks - A Hard Day's Night - Full Instrumental.mp3", instrument: "flute" },
    { title: "A Whole New World (From 'Aladdin') - Full Instrumental", file: "The Backing Tracks - A Whole New World (From 'Aladdin') - Full Instrumental.mp3", instrument: "flute" },
    { title: "Every Breath You Take - Full Instrumental", file: "The Backing Tracks - Every Breath You Take - Full Instrumental.mp3", instrument: "flute" },
    { title: "Just Dance - Demonstration Track", file: "The Great Backing Orchestra - Just Dance - Demonstration Track.mp3", instrument: "flute" },
    { title: "My Ordinary Life", file: "The Living Tombstone - My Ordinary Life.mp3", instrument: "flute" },
    { title: "Nights In White Satin", file: "The Moody Blues - Nights In White Satin.mp3", instrument: "flute" },
    { title: "Cruella De Ville - from 101 Dalmatians", file: "The Soundlike Ensemble - Cruella De Ville - from 101 Dalmatians.mp3", instrument: "flute" },
    { title: "Beyond the Wall", file: "broom closets - Beyond the Wall.mp3", instrument: "flute" },
    { title: "Always with Me (From Spirited Away)", file: "daigoro789 - Always with Me- Spirited Away.mp3", instrument: "flute" },
    { title: "How Far I'll Go (From 'Moana')", file: "daigoro789 - How Far I'll Go (From 'Moana') [For Flute & Piano Duet].mp3", instrument: "flute" },
    { title: "I See the Light (From 'Tangled')", file: "daigoro789 - I See the Light (From 'Tangled') [For Flute & Piano Duet].mp3", instrument: "flute" },
    { title: "One Summer's Day (From 'Spirited Away')", file: "daigoro789 - One Summer's Day (From 'Spirited Away') [For Flute & Piano Duet].mp3", instrument: "flute" },
    { title: "Under the Sea (From 'The Little Mermaid')", file: "daigoro789 - Under the Sea (From 'The Little Mermaid') [For Flute & Piano Duet].mp3", instrument: "flute" },

    // trumpets
    { title: "A Night In Tunisia - Take 3", file: "Art Blakey & The Jazz Messengers - A Night In Tunisia - Take 3.mp3", instrument: "trumpet" },
    { title: "Spinning Wheel", file: "Blood, Sweat & Tears - Spinning Wheel.mp3", instrument: "trumpet" },
    { title: "I Will Survive", file: "CAKE - I Will Survive.mp3", instrument: "trumpet" },
    { title: "The Distance", file: "CAKE - The Distance.mp3", instrument: "trumpet" },
    { title: "Safe And Sound", file: "Capital Cities - Safe And Sound.mp3", instrument: "trumpet" },
    { title: "Ko Ko", file: "Charlie Parker - Ko Ko.mp3", instrument: "trumpet" },
    { title: "My Ideal - Remastered 2004", file: "Chet Baker - My Ideal - Remastered 2004.mp3", instrument: "trumpet" },
    { title: "Beginnings", file: "Chicago - Beginings.mp3", instrument: "trumpet" },
    { title: "Laura", file: "Clifford Brown, Max Roach - Laura.mp3", instrument: "trumpet" },
    { title: "Cherokee", file: "Clifford Brown, Max Roach Quintet - Cherokee.mp3", instrument: "trumpet" },
    { title: "Body and Soul", file: "Coleman Hawkins - Body and Soul.mp3", instrument: "trumpet" },
    { title: "Misirlou", file: "Dick Dale - Misirlou.mp3", instrument: "trumpet" },
    { title: "I Can't Get Started 'Round Midnight", file: "Dizzy Gillespie - I Can't Get Started 'Round Midnight.mp3", instrument: "trumpet" },
    { title: "I Can't Get Started", file: "Dizzy Gillespie - I Can't Get Started.mp3", instrument: "trumpet" },
    { title: "The Eternal Triangle", file: "Dizzy Gillespie, Sonny Rollins, Sonny Stitt - The Eternal Triangle.mp3", instrument: "trumpet" },
    { title: "Shipbuilding", file: "Elvis Costello & The Attractions - Shipbuilding.mp3", instrument: "trumpet" },
    { title: "Maiden Voyage", file: "Herbie Hancock - Maiden Voyage.mp3", instrument: "trumpet" },
    { title: "Grazing In The Grass", file: "Hugh Masekela - Grazing In The Grass.mp3", instrument: "trumpet" },
    { title: "trumpet", file: "Jason Derulo - Trumpets.mp3", instrument: "trumpet" },
    { title: "Ring of Fire", file: "Johnny Cash - Ring of Fire.mp3", instrument: "trumpet" },
    { title: "Jungle Boogie", file: "Kool & The Gang - Jungle Boogie.mp3", instrument: "trumpet" },
    { title: "West End Blues", file: "Louis Armstrong - West End Blues.mp3", instrument: "trumpet" },
    { title: "One Step Beyond - 2009 - Remaster", file: "Madness - One Step Beyond - 2009 - Remaster.mp3", instrument: "trumpet" },
    { title: "Valerie (feat. Amy Winehouse) - Version Revisited", file: "Mark Ronson, Amy Winehouse - Valerie (feat. Amy Winehouse) - Version Revisited.mp3", instrument: "trumpet" },
    { title: "Uptown Funk (feat. Bruno Mars)", file: "Mark Ronson, Bruno Mars - Uptown Funk (feat. Bruno Mars).mp3", instrument: "trumpet" },
    { title: "God Put a Smile on Your Face (feat. The Daptone Horns)", file: "Mark Ronson, The Daptone Horns - God Put a Smile on Your Face (feat. The Daptone Horns).mp3", instrument: "trumpet" },
    { title: "Beds Are Burning - Remastered", file: "Midnight Oil - Beds Are Burning - Remastered.mp3", instrument: "trumpet" },
    { title: "Wedding Cake Island - Remastered", file: "Midnight Oil - Wedding Cake Island - Remastered.mp3", instrument: "trumpet" },
    { title: "Stella By Starlight (feat. John Coltrane & Bill Evans)", file: "Miles Davis, John Coltrane, Bill Evans - Stella By Starlight (feat. John Coltrane & Bill Evans).mp3", instrument: "trumpet" },
    { title: "So What (feat. John Coltrane, Cannonball Adderley & Bill Evans)", file: "Miles Davis, John Coltrane, Cannonball Adderley, Bill Evans - So What (feat. John Coltrane, Cannonball Adderley & Bill Evans).mp3", instrument: "trumpet" },
    { title: "Life In a Glasshouse", file: "Radiohead - Life In a Glasshouse.mp3", instrument: "trumpet" },
    { title: "Take On Me", file: "Reel Big Fish - Take On Me.mp3", instrument: "trumpet" },
    { title: "The Royal Scam", file: "Steely Dan - The Royal Scam.mp3", instrument: "trumpet" },
    { title: "Embraceable You", file: "The Art Farmer Quartet - Embraceable You.mp3", instrument: "trumpet" },
    { title: "Penny Lane - Remastered", file: "The Beatles - Penny Lane - Remastered.mp3", instrument: "trumpet" },
    { title: "Sly (Radio Edit)", file: "The Cat Empire - Sly (Radio Edit).mp3", instrument: "trumpet" },
    { title: "Close To Me", file: "The Cure - Close To Me.mp3", instrument: "trumpet" },
    { title: "The Impression That I Get", file: "The Mighty Mighty Bosstones - The Impression That I Get.mp3", instrument: "trumpet" },
    { title: "Ghost Town", file: "The Specials - Ghost Town.mp3", instrument: "trumpet" },
    { title: "Papa Was A Rollin' Stone - Single Version", file: "The Temptations - Papa Was A Rollin' Stone - Single Version.mp3", instrument: "trumpet" },
    { title: "What Is Hip", file: "Tower Of Power - What Is Hip.mp3", instrument: "trumpet" },
    { title: "Red Light - Remastered 2008", file: "U2 - Red Light - Remastered 2008.mp3", instrument: "trumpet" },
    { title: "Cantaloop (Flip Fantasia)", file: "Us3, Rahsaan, Gerard Presencer - Cantaloop (Flip Fantasia).mp3", instrument: "trumpet" },

    // test
    { title: "15.00Hz", file: "sine_wave_15.00Hz_30s.wav", instrument: "test" },
    { title: "20.00Hz", file: "sine_wave_20.00Hz_30s.wav", instrument: "test" },
    { title: "30.00Hz", file: "sine_wave_30.00Hz_30s.wav", instrument: "test" },
    { title: "40.00Hz", file: "sine_wave_40.00Hz_30s.wav", instrument: "test" },
    { title: "50.00Hz", file: "sine_wave_50.00Hz_30s.wav", instrument: "test" },
    { title: "60.00Hz", file: "sine_wave_60.00Hz_30s.wav", instrument: "test" },
    { title: "70.00Hz", file: "sine_wave_70.00Hz_30s.wav", instrument: "test" },
    { title: "80.00Hz", file: "sine_wave_80.00Hz_30s.wav", instrument: "test" },
    { title: "90.00Hz", file: "sine_wave_90.00Hz_30s.wav", instrument: "test" },
    { title: "100.00Hz", file: "sine_wave_100.00Hz_30s.wav", instrument: "test" },
    { title: "110.00Hz", file: "sine_wave_110.00Hz_30s.wav", instrument: "test" },
    { title: "120.00Hz", file: "sine_wave_120.00Hz_30s.wav", instrument: "test" },
    { title: "130.00Hz", file: "sine_wave_130.00Hz_30s.wav", instrument: "test" },
    { title: "140.00Hz", file: "sine_wave_140.00Hz_30s.wav", instrument: "test" },
    { title: "150.00Hz", file: "sine_wave_150.00Hz_30s.wav", instrument: "test" },
    { title: "160.00Hz", file: "sine_wave_160.00Hz_30s.wav", instrument: "test" },
    { title: "170.00Hz", file: "sine_wave_170.00Hz_30s.wav", instrument: "test" },
    { title: "180.00Hz", file: "sine_wave_180.00Hz_30s.wav", instrument: "test" },
    { title: "190.00Hz", file: "sine_wave_190.00Hz_30s.wav", instrument: "test" },
    { title: "200.00Hz", file: "sine_wave_200.00Hz_30s.wav", instrument: "test" },
    { title: "240.00Hz", file: "sine_wave_240.00Hz_30s.wav", instrument: "test" },
    { title: "480.00Hz", file: "sine_wave_480.00Hz_30s.wav", instrument: "test" },
    { title: "960.00Hz", file: "sine_wave_960.00Hz_30s.wav", instrument: "test" },
    { title: "16.35Hz", file: "sine_wave_16.35Hz_30s.wav", instrument: "test" },
    { title: "17.32Hz", file: "sine_wave_17.32Hz_30s.wav", instrument: "test" },
    { title: "18.35Hz", file: "sine_wave_18.35Hz_30s.wav", instrument: "test" },
    { title: "19.45Hz", file: "sine_wave_19.45Hz_30s.wav", instrument: "test" },
    { title: "20.60Hz", file: "sine_wave_20.60Hz_30s.wav", instrument: "test" },
    { title: "21.83Hz", file: "sine_wave_21.83Hz_30s.wav", instrument: "test" },
    { title: "23.12Hz", file: "sine_wave_23.12Hz_30s.wav", instrument: "test" },
    { title: "24.50Hz", file: "sine_wave_24.50Hz_30s.wav", instrument: "test" },
    { title: "25.96Hz", file: "sine_wave_25.96Hz_30s.wav", instrument: "test" },
    { title: "27.50Hz", file: "sine_wave_27.50Hz_30s.wav", instrument: "test" },
    { title: "29.14Hz", file: "sine_wave_29.14Hz_30s.wav", instrument: "test" },
    { title: "30.87Hz", file: "sine_wave_30.87Hz_30s.wav", instrument: "test" },
    { title: "32.70Hz", file: "sine_wave_32.70Hz_30s.wav", instrument: "test" },
    { title: "34.65Hz", file: "sine_wave_34.65Hz_30s.wav", instrument: "test" },
    { title: "36.71Hz", file: "sine_wave_36.71Hz_30s.wav", instrument: "test" },
    { title: "38.89Hz", file: "sine_wave_38.89Hz_30s.wav", instrument: "test" },
    { title: "41.20Hz", file: "sine_wave_41.20Hz_30s.wav", instrument: "test" },
    { title: "43.65Hz", file: "sine_wave_43.65Hz_30s.wav", instrument: "test" },
    { title: "46.25Hz", file: "sine_wave_46.25Hz_30s.wav", instrument: "test" },
    { title: "49.00Hz", file: "sine_wave_49.00Hz_30s.wav", instrument: "test" },
    { title: "51.91Hz", file: "sine_wave_51.91Hz_30s.wav", instrument: "test" },
    { title: "55.00Hz", file: "sine_wave_55.00Hz_30s.wav", instrument: "test" },
    { title: "58.27Hz", file: "sine_wave_58.27Hz_30s.wav", instrument: "test" },
    { title: "61.74Hz", file: "sine_wave_61.74Hz_30s.wav", instrument: "test" },
    { title: "65.41Hz", file: "sine_wave_65.41Hz_30s.wav", instrument: "test" },
    { title: "69.30Hz", file: "sine_wave_69.30Hz_30s.wav", instrument: "test" },
    { title: "73.42Hz", file: "sine_wave_73.42Hz_30s.wav", instrument: "test" },
    { title: "77.78Hz", file: "sine_wave_77.78Hz_30s.wav", instrument: "test" },
    { title: "82.41Hz", file: "sine_wave_82.41Hz_30s.wav", instrument: "test" },
    { title: "87.31Hz", file: "sine_wave_87.31Hz_30s.wav", instrument: "test" },
    { title: "92.50Hz", file: "sine_wave_92.50Hz_30s.wav", instrument: "test" },
    { title: "98.00Hz", file: "sine_wave_98.00Hz_30s.wav", instrument: "test" },
    { title: "103.83Hz", file: "sine_wave_103.83Hz_30s.wav", instrument: "test" },
    { title: "110.00Hz", file: "sine_wave_110.00Hz_30s.wav", instrument: "test" },
    { title: "116.54Hz", file: "sine_wave_116.54Hz_30s.wav", instrument: "test" },
    { title: "123.47Hz", file: "sine_wave_123.47Hz_30s.wav", instrument: "test" },
    { title: "123.00Hz", file: "sine_wave_123.00Hz_30s.wav", instrument: "test" },
    { title: "124.00Hz", file: "sine_wave_124.00Hz_30s.wav", instrument: "test" },
    { title: "125.00Hz", file: "sine_wave_125.00Hz_30s.wav", instrument: "test" },
    { title: "124.10Hz", file: "sine_wave_124.10Hz_30s.wav", instrument: "test" },
    { title: "124.20Hz", file: "sine_wave_124.20Hz_30s.wav", instrument: "test" },
    { title: "124.30Hz", file: "sine_wave_124.30Hz_30s.wav", instrument: "test" },
    { title: "124.40Hz", file: "sine_wave_124.40Hz_30s.wav", instrument: "test" },
    { title: "124.50Hz", file: "sine_wave_124.50Hz_30s.wav", instrument: "test" },
    { title: "124.60Hz", file: "sine_wave_124.60Hz_30s.wav", instrument: "test" },
    { title: "124.70Hz", file: "sine_wave_124.70Hz_30s.wav", instrument: "test" },
    { title: "124.80Hz", file: "sine_wave_124.80Hz_30s.wav", instrument: "test" },
    { title: "124.90Hz", file: "sine_wave_124.90Hz_30s.wav", instrument: "test" },
    { title: "125.00Hz", file: "sine_wave_125.00Hz_30s.wav", instrument: "test" },
    { title: "125.10Hz", file: "sine_wave_125.10Hz_30s.wav", instrument: "test" },
    { title: "125.20Hz", file: "sine_wave_125.20Hz_30s.wav", instrument: "test" },
    { title: "125.30Hz", file: "sine_wave_125.30Hz_30s.wav", instrument: "test" },
    { title: "125.40Hz", file: "sine_wave_125.40Hz_30s.wav", instrument: "test" },
    { title: "125.50Hz", file: "sine_wave_125.50Hz_30s.wav", instrument: "test" },
    { title: "125.60Hz", file: "sine_wave_125.60Hz_30s.wav", instrument: "test" },
    { title: "125.70Hz", file: "sine_wave_125.70Hz_30s.wav", instrument: "test" },
    { title: "125.80Hz", file: "sine_wave_125.80Hz_30s.wav", instrument: "test" },
    { title: "125.90Hz", file: "sine_wave_125.90Hz_30s.wav", instrument: "test" },
    { title: "126.00Hz", file: "sine_wave_126.00Hz_30s.wav", instrument: "test" },
    { title: "127.00Hz", file: "sine_wave_127.00Hz_30s.wav", instrument: "test" },
    { title: "128.00Hz", file: "sine_wave_128.00Hz_30s.wav", instrument: "test" },
    { title: "129.00Hz", file: "sine_wave_129.00Hz_30s.wav", instrument: "test" },
    { title: "130.00Hz", file: "sine_wave_130.00Hz_30s.wav", instrument: "test" },
    { title: "130.81Hz", file: "sine_wave_130.81Hz_30s.wav", instrument: "test" },
    { title: "138.59Hz", file: "sine_wave_138.59Hz_30s.wav", instrument: "test" },
    { title: "146.83Hz", file: "sine_wave_146.83Hz_30s.wav", instrument: "test" },
    { title: "155.56Hz", file: "sine_wave_155.56Hz_30s.wav", instrument: "test" },
    { title: "164.81Hz", file: "sine_wave_164.81Hz_30s.wav", instrument: "test" },
    { title: "174.61Hz", file: "sine_wave_174.61Hz_30s.wav", instrument: "test" },
    { title: "185.00Hz", file: "sine_wave_185.00Hz_30s.wav", instrument: "test" },
    { title: "196.00Hz", file: "sine_wave_196.00Hz_30s.wav", instrument: "test" },
    { title: "207.65Hz", file: "sine_wave_207.65Hz_30s.wav", instrument: "test" },
    { title: "220.00Hz", file: "sine_wave_220.00Hz_30s.wav", instrument: "test" },
    { title: "233.08Hz", file: "sine_wave_233.08Hz_30s.wav", instrument: "test" },
    { title: "246.94Hz", file: "sine_wave_246.94Hz_30s.wav", instrument: "test" },
    { title: "261.63Hz", file: "sine_wave_261.63Hz_30s.wav", instrument: "test" },
    { title: "277.18Hz", file: "sine_wave_277.18Hz_30s.wav", instrument: "test" },
    { title: "293.66Hz", file: "sine_wave_293.66Hz_30s.wav", instrument: "test" },
    { title: "311.13Hz", file: "sine_wave_311.13Hz_30s.wav", instrument: "test" },
    { title: "329.63Hz", file: "sine_wave_329.63Hz_30s.wav", instrument: "test" },
    { title: "349.23Hz", file: "sine_wave_349.23Hz_30s.wav", instrument: "test" },
    { title: "369.99Hz", file: "sine_wave_369.99Hz_30s.wav", instrument: "test" },
    { title: "392.00Hz", file: "sine_wave_392.00Hz_30s.wav", instrument: "test" },
    { title: "415.30Hz", file: "sine_wave_415.30Hz_30s.wav", instrument: "test" },
    { title: "440.00Hz", file: "sine_wave_440.00Hz_30s.wav", instrument: "test" },
    { title: "466.16Hz", file: "sine_wave_466.16Hz_30s.wav", instrument: "test" },
    { title: "493.88Hz", file: "sine_wave_493.88Hz_30s.wav", instrument: "test" },
    { title: "523.25Hz", file: "sine_wave_523.25Hz_30s.wav", instrument: "test" },
    { title: "554.37Hz", file: "sine_wave_554.37Hz_30s.wav", instrument: "test" },
    { title: "587.33Hz", file: "sine_wave_587.33Hz_30s.wav", instrument: "test" },
    { title: "622.25Hz", file: "sine_wave_622.25Hz_30s.wav", instrument: "test" },
    { title: "659.25Hz", file: "sine_wave_659.25Hz_30s.wav", instrument: "test" },
    { title: "698.46Hz", file: "sine_wave_698.46Hz_30s.wav", instrument: "test" },
    { title: "739.99Hz", file: "sine_wave_739.99Hz_30s.wav", instrument: "test" },
    { title: "783.99Hz", file: "sine_wave_783.99Hz_30s.wav", instrument: "test" },
    { title: "830.61Hz", file: "sine_wave_830.61Hz_30s.wav", instrument: "test" },
    { title: "880.00Hz", file: "sine_wave_880.00Hz_30s.wav", instrument: "test" },
    { title: "932.33Hz", file: "sine_wave_932.33Hz_30s.wav", instrument: "test" },
    { title: "987.77Hz", file: "sine_wave_987.77Hz_30s.wav", instrument: "test" },
    { title: "1046.50Hz", file: "sine_wave_1046.50Hz_30s.wav", instrument: "test" },
    { title: "1108.73Hz", file: "sine_wave_1108.73Hz_30s.wav", instrument: "test" },
    { title: "1174.66Hz", file: "sine_wave_1174.66Hz_30s.wav", instrument: "test" },
    { title: "1244.51Hz", file: "sine_wave_1244.51Hz_30s.wav", instrument: "test" },
    { title: "1318.51Hz", file: "sine_wave_1318.51Hz_30s.wav", instrument: "test" },
    { title: "1396.91Hz", file: "sine_wave_1396.91Hz_30s.wav", instrument: "test" },
    { title: "1479.98Hz", file: "sine_wave_1479.98Hz_30s.wav", instrument: "test" },
    { title: "1567.98Hz", file: "sine_wave_1567.98Hz_30s.wav", instrument: "test" },
    { title: "1661.22Hz", file: "sine_wave_1661.22Hz_30s.wav", instrument: "test" },
    { title: "1760.00Hz", file: "sine_wave_1760.00Hz_30s.wav", instrument: "test" },
    { title: "1864.66Hz", file: "sine_wave_1864.66Hz_30s.wav", instrument: "test" },
    { title: "1975.53Hz", file: "sine_wave_1975.53Hz_30s.wav", instrument: "test" },
    { title: "2093.00Hz", file: "sine_wave_2093.00Hz_30s.wav", instrument: "test" },
    { title: "2217.46Hz", file: "sine_wave_2217.46Hz_30s.wav", instrument: "test" },
    { title: "2349.32Hz", file: "sine_wave_2349.32Hz_30s.wav", instrument: "test" },
    { title: "2489.02Hz", file: "sine_wave_2489.02Hz_30s.wav", instrument: "test" },
    { title: "2637.02Hz", file: "sine_wave_2637.02Hz_30s.wav", instrument: "test" },
    { title: "2793.83Hz", file: "sine_wave_2793.83Hz_30s.wav", instrument: "test" },
    { title: "2959.96Hz", file: "sine_wave_2959.96Hz_30s.wav", instrument: "test" },
    { title: "3135.96Hz", file: "sine_wave_3135.96Hz_30s.wav", instrument: "test" },
    { title: "3322.44Hz", file: "sine_wave_3322.44Hz_30s.wav", instrument: "test" },
    { title: "3520.00Hz", file: "sine_wave_3520.00Hz_30s.wav", instrument: "test" },
    { title: "3729.31Hz", file: "sine_wave_3729.31Hz_30s.wav", instrument: "test" },
    { title: "3951.07Hz", file: "sine_wave_3951.07Hz_30s.wav", instrument: "test" },
    { title: "4186.01Hz", file: "sine_wave_4186.01Hz_30s.wav", instrument: "test" },

    // MIDI
    { title: "30 Seconds 2 Mars - Bright Lights", file: "30 Seconds 2 Mars - Bright Lights.mid", instrument: "midi" },
    { title: "30 Seconds 2 Mars - City Of Angels", file: "30 Seconds 2 Mars - City Of Angels.mid", instrument: "midi" },
    { title: "30 Seconds To Mars - This Is War", file: "30 Seconds To Mars - This Is War.mid", instrument: "midi" },
    { title: "5 Beautiful songs (hands are divided)", file: "5 Beautiful songs (hands are divided).mid", instrument: "midi" },
    { title: "50 Ways to Say Goodbye", file: "50 Ways to Say Goodbye.mid", instrument: "midi" },
    { title: "5sos - Heartbreak Girl - Pianoitall", file: "5sos - Heartbreak Girl - Pianoitall.mid", instrument: "midi" },
    { title: "A Thousand Miles", file: "A Thousand Miles.mid", instrument: "midi" },
    { title: "Adam's Song - Blink 182 - Rinerion", file: "Adam's Song - Blink 182 - Rinerion.mid", instrument: "midi" },
    { title: "Adele - Skyfall (Pianoitall.com)", file: "Adele - Skyfall (Pianoitall.com).mid", instrument: "midi" },
    { title: "Adele - hello (hands are divided)", file: "Adele - hello (hands are divided).mid", instrument: "midi" },
    { title: "Africa - Toto", file: "Africa_-_Toto.mid", instrument: "midi" },
    { title: "Alan Walker & Ava Max - Alone, Pt. II (Hands are divided)", file: "Alan Walker & Ava Max - Alone, Pt. II (Hands are divided).mid", instrument: "midi" },
    { title: "Alan Walker - Diamond Heart (feat. Sophia Somajo)", file: "Alan Walker - Diamond Heart (feat. Sophia Somajo).mid", instrument: "midi" },
    { title: "Alan Walker - Faded [Rousseau Cover MIDI]", file: "Alan Walker - Faded [Rousseau Cover MIDI].mid", instrument: "midi" },
    { title: "Alan Walker - Faded", file: "Alan Walker - Faded.mid", instrument: "midi" },
    { title: "Alan-Walker-K-391-IGNITE", file: "Alan-Walker-K-391-IGNITE-MIDI.mid", instrument: "midi" },
    { title: "Alan Walker - Faded", file: "Alan_Walker_Faded.mid", instrument: "midi" },
    { title: "All of Me", file: "All of Me.mid", instrument: "midi" },
    { title: "Alone - Alan Walker (hands are divided)", file: "Alone - Alan Walker (hands are divided).mid", instrument: "midi" },
    { title: "Andy Williams - Love Story Where Do I Begin", file: "Andy Williams - Love Story Where Do I Begin.mid", instrument: "midi" },
    { title: "Avicii - Wake Me Up (Advanced)", file: "Avicii - Wake Me Up (Advanced).mid", instrument: "midi" },
    { title: "BLACKPINCK - How You Like That (hands are divided)", file: "BLACKPINCK - How You Like That (hands are divided).mid", instrument: "midi" },
    { title: "Bad Romance Midi", file: "Bad Romance Midi.mid", instrument: "midi" },
    { title: "Bastille - Pompeii", file: "Bastille - Pompeii.mid", instrument: "midi" },
    { title: "Beatles - Hey Jude", file: "Beatles - Hey Jude.mid", instrument: "midi" },
    { title: "Beethoven - Moonlight Sonata (Pianoitall.com)", file: "Beethoven - Moonlight Sonata (Pianoitall.com).mid", instrument: "midi" },
    { title: "Beethoven Symphony No7", file: "Beethoven_Symphony_No7.mid", instrument: "midi" },
    { title: "Billie Eilish - No Time To Die (hands are divided)", file: "Billie Eilish - No Time To Die (hands are divided).mid", instrument: "midi" },
    { title: "Billie Eilish - bury a friend [Rousseau Cover MIDI]", file: "Billie Eilish - bury a friend [Rousseau Cover MIDI].mid", instrument: "midi" },
    { title: "Billy Joel - Piano Man", file: "Billy Joel - Piano Man.mid", instrument: "midi" },
    { title: "Bruno Mars - Talking to the moon (hands are divided)", file: "Bruno Mars - Talking to the moon (hands are divided).mid", instrument: "midi" },
    { title: "Bruno Mars - When I Was Your Man (Pianoitall.com)", file: "Bruno Mars - When I Was Your Man (Pianoitall.com).mid", instrument: "midi" },
    { title: "Call Me Maybe - Carly Rae Jepsen [MIDICollection.net]", file: "Call Me Maybe - Carly Rae Jepsen [MIDICollection.net].mid", instrument: "midi" },
    { title: "Calvin Harris ft. Ellie Goulding - I Need Your Love (Advanced)", file: "Calvin Harris ft. Ellie Goulding - I Need Your Love (Advanced).mid", instrument: "midi" },
    { title: "Calvin Harris ft. Ellie Goulding - I Need Your Love (Simple)", file: "Calvin Harris ft. Ellie Goulding - I Need Your Love (Simple).mid", instrument: "midi" },
    { title: "Capital Cities - Safe And Sound", file: "Capital Cities - Safe And Sound.mid", instrument: "midi" },
    { title: "Carly Rae Jepsen - This Kiss (Advanced)", file: "Carly Rae Jepsen - This Kiss (Advanced).mid", instrument: "midi" },
    { title: "Celine Dion - My Heart Will Go On (Advanced)", file: "Celine Dion - My Heart Will Go On (Advanced).mid", instrument: "midi" },
    { title: "Celine Dion - My Heart Will Go On (Beginner)", file: "Celine Dion - My Heart Will Go On (Beginner).mid", instrument: "midi" },
    { title: "Cher Lloyd ft. Becky B - Oath (Advanced)", file: "Cher Lloyd ft. Becky B - Oath (Advanced).mid", instrument: "midi" },
    { title: "Children", file: "Children.mid", instrument: "midi" },
    { title: "Christina Perri - A Thousand Years", file: "Christina Perri - A Thousand Years.mid", instrument: "midi" },
    { title: "Christina Perri - Human (Hard)", file: "Christina Perri - Human (Hard).mid", instrument: "midi" },
    { title: "Christina Perri - Jar Of Hearts", file: "Christina Perri - Jar Of Hearts.mid", instrument: "midi" },
    { title: "Colbie Caillat - Try - Pianoitall", file: "Colbie Caillat - Try - Pianoitall.mid", instrument: "midi" },
    { title: "Coldplay - Atlas", file: "Coldplay - Atlas.mid", instrument: "midi" },
    { title: "Coldplay - Clocks", file: "Coldplay - Clocks.mid", instrument: "midi" },
    { title: "Coldplay - Every Teardrop is a Waterfall", file: "Coldplay - Every Teardrop is a Waterfall.mid", instrument: "midi" },
    { title: "Coldplay - Hymn For The Weekend (hands are divided)", file: "Coldplay - Hymn For The Weekend (hands are divided).mid", instrument: "midi" },
    { title: "Coldplay - The Scientist", file: "Coldplay - The Scientist.mid", instrument: "midi" },
    { title: "Coldplay - Clocks (Adrian Lee Piano Version)", file: "Coldplay_-_Clocks_(Adrian_Lee_Piano_Version).mid", instrument: "midi" },
    { title: "Coldplay - The Scientist", file: "Coldplay_-_The_Scientist.mid", instrument: "midi" },
    { title: "Coldplay - Viva La Vida", file: "Coldplay_-_Viva_La_Vida.mid", instrument: "midi" },
    { title: "Coldplay - Yellow (Adrian Lee Piano Version)", file: "Coldplay_-_Yellow_(Adrian_Lee_Piano_Version).mid", instrument: "midi" },
    { title: "Coolio - Gangsta's Paradise (hands are divided)", file: "Coolio - Gangsta's Paradise (hands are divided).mid", instrument: "midi" },
    { title: "Coolio - Gangsta's Paradise [Remake] (hands are divided)", file: "Coolio - Gangsta's Paradise [Remake] (hands are divided).mid", instrument: "midi" },
    { title: "David Guetta ft. Sia - She Wolf (Advanced)", file: "David Guetta ft. Sia - She Wolf (Advanced).mid", instrument: "midi" },
    { title: "Davy Jones", file: "Davy Jones.mid", instrument: "midi" },
    { title: "Daybreak - Piano Solo in C Major", file: "Daybreak - Piano Solo in C Major.mid", instrument: "midi" },
    { title: "Demi Lovato - Heart Attack (Advanced)", file: "Demi Lovato - Heart Attack (Advanced).mid", instrument: "midi" },
    { title: "Demi Lovato - Heart Attack (Pianoitall.com)", file: "Demi Lovato - Heart Attack (Pianoitall.com).mid", instrument: "midi" },
    { title: "Demi Lovato - Heart Attack (Simple)", file: "Demi Lovato - Heart Attack (Simple).mid", instrument: "midi" },
    { title: "Demi Lovato - Made In The USA (Advanced)", file: "Demi Lovato - Made In The USA (Advanced).mid", instrument: "midi" },
    { title: "Demons (LSAnonymous300)", file: "Demons (LSAnonymous300).mid", instrument: "midi" },
    { title: "Despacito_Piano_Sheet_Music_Luis_Fonsi_ft_Daddy_Yankee", file: "Despacito_Piano_Sheet_Music_Luis_Fonsi_ft_Daddy_Yankee.mid", instrument: "midi" },
    { title: "Die For You", file: "Die_For_You.mid", instrument: "midi" },
    { title: "Dr Dre - Still Dre", file: "Dr Dre - Still Dre.mid", instrument: "midi" },
    { title: "Drink Up Me Hearties", file: "Drink Up Me Hearties.mid", instrument: "midi" },
    { title: "Duncan Laurence feat. Fletcher - Arcade (hands are divided)", file: "Duncan Laurence feat. Fletcher - Arcade (hands are divided).mid", instrument: "midi" },
    { title: "Ed Sheeran - Autumn Leaves (Advanced)", file: "Ed Sheeran - Autumn Leaves (Advanced).mid", instrument: "midi" },
    { title: "Ed Sheeran - Autumn Leaves (Simple)", file: "Ed Sheeran - Autumn Leaves (Simple).mid", instrument: "midi" },
    { title: "Ed Sheeran - Happier", file: "Ed Sheeran - Happier.mid", instrument: "midi" },
    { title: "Ed Sheeran - The A Team (Pianoitall.com)", file: "Ed Sheeran - The A Team(Pianoitall.com).mid", instrument: "midi" },
    { title: "Ed Sheeran - Perfect (Piano Cover)", file: "Ed_Sheeran_Perfect_Piano_Cover.mid", instrument: "midi" },
    { title: "Eminem - Rap God (Hard Version)", file: "Eminem - Rap God (Hard Version).mid", instrument: "midi" },
    { title: "Ennio Morricone - Chi Mai", file: "Ennio Morricone - Chi Mai.MID", instrument: "midi" },
    { title: "Eric Clapton - Tears In Heaven (Advanced)", file: "Eric Clapton - Tears In Heaven (Advanced).mid", instrument: "midi" },
    { title: "Eric Thiman - Flood Time", file: "Eric Thiman - Flood Time.mid", instrument: "midi" },
    { title: "Euphemia Allen - Chopsticks", file: "Euphemia_Allen_Chopsticks.mid", instrument: "midi" },
    { title: "Evanescence - My Immortal", file: "Evanescence - My Immortal.mid", instrument: "midi" },
    { title: "Everybody Talks", file: "Everybody Talks.mid", instrument: "midi" },
    { title: "Experience - Ludovico Einaudi", file: "Experience_-_Ludovico_Einaudi.mid", instrument: "midi" },
    { title: "Faded", file: "Faded.mid", instrument: "midi" },
    { title: "Fall For You - Second Hand Serenade", file: "Fall For You - Second Hand Serenade.mid", instrument: "midi" },
    { title: "Fall Out Boy - My Songs Know What You Did In The Dark (Simple)", file: "Fall Out Boy - My Songs Know What You Did In The Dark (Simple).mid", instrument: "midi" },
    { title: "Farewell Hyrule King", file: "Farewell Hyrule King.mid", instrument: "midi" },
    { title: "Feed Me + Ellie Goulding - Relocating The Lights (Evan Duffy Piano Version)", file: "Feed_Me_+_Ellie_Goulding_-_Relocating_The_Lights_(Evan_Duffy_Piano_Version).mid", instrument: "midi" },
    { title: "Franz Liszt - Liebestraum No. 3", file: "Franz Liszt - Liebestraum No. 3.mid", instrument: "midi" },
    { title: "Full MIDI - Billie Eilish - No Time to Die (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Billie Eilish - No Time to Die (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Billie Eilish, Khalid - Lovely (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Billie Eilish, Khalid - Lovely (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Bryan Adams - Heaven (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Bryan Adams - Heaven (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Calum Scott - You Are The Reason (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Calum Scott - You Are The Reason (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Chord Overstreet - Hold On (Atlantic Lights Arrangement + Tempo +Key +Colors)", file: "Full MIDI - Chord Overstreet - Hold On (Atlantic Lights Arrangement + Tempo +Key +Colors).mid", instrument: "midi" },
    { title: "Full MIDI - Ed Sheeran - Lego House (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Ed Sheeran - Lego House (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Elvis Presley - Can't Help Falling in Love (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Elvis Presley - Can't Help Falling in Love (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Enya - Only Time (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Enya - Only Time (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Evanescence - Lithium (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "Full MIDI - Evanescence - Lithium (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "Full MIDI - George Michael - Careless Whisper (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - George Michael - Careless Whisper (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Harry Styles - Sign of the Times (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Harry Styles - Sign of the Times (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - JVKE - Golden Hour (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - JVKE - Golden Hour (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Jamie Duffy - Solas (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "Full MIDI - Jamie Duffy - Solas (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "Full MIDI - Labrinth - Jealous (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "Full MIDI - Labrinth - Jealous (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "Full MIDI - Lauren Daigle - You Say (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "Full MIDI - Lauren Daigle - You Say (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "Full MIDI - Ludovico Einaudi - I Giorni (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Ludovico Einaudi - I Giorni (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Ludovico Einaudi - Nuvole Bianche (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Ludovico Einaudi - Nuvole Bianche (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - Olivia Rodrigo - Traitor (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - Olivia Rodrigo - Traitor (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - SYML - Where's My Love (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - SYML - Where's My Love (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - The Beatles - Yesterday (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - The Beatles - Yesterday (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - The Calling - Wherever You Will Go (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - The Calling - Wherever You Will Go (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Full MIDI - The Fray - How To Save a Life (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "Full MIDI - The Fray - How To Save a Life (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "Full MIDI - The Police - Every Breath You Take (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "Full MIDI - The Police - Every Breath You Take (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "Fur Elise", file: "Fur_Elise.mid", instrument: "midi" },
    { title: "Game of Thrones Easy Piano", file: "Game_of_Thrones_Easy_piano.mid", instrument: "midi" },
    { title: "Game of Thrones Main Theme", file: "Game_of_Thrones_Main_Theme.mid", instrument: "midi" },
    { title: "Gary Jules - Mad World", file: "Gary Jules - Mad World.mid", instrument: "midi" },
    { title: "Good Bad Ugly", file: "Good_bad_ugly.mid", instrument: "midi" },
    { title: "Gravity Falls Opening - Intermediate Piano Solo", file: "Gravity_Falls_Opening_-_Intermediate_Piano_Solo.mid", instrument: "midi" },
    { title: "Green Day - Good Riddence (Advanced)", file: "Green Day - Good Riddence (Advanced).mid", instrument: "midi" },
    { title: "Green Day - Good Riddence (Beginner)", file: "Green Day - Good Riddence (Beginner).mid", instrument: "midi" },
    { title: "Guy Sebastian ft. Lupe Fiasco - Battle Scars (Advanced)", file: "Guy Sebastian ft. Lupe Fiasco - Battle Scars (Advanced).mid", instrument: "midi" },
    { title: "HTTYD - See You Tomorrow", file: "HTTYD - See You Tomorrow.mid", instrument: "midi" },
    { title: "Hall of Fame - The Script [MIDICollection.net]", file: "Hall of Fame - The Script [MIDICollection.net].mid", instrument: "midi" },
    { title: "Hans Zimmer - First Step - Interstellar", file: "Hans Zimmer - First Step - Interstellar.mid", instrument: "midi" },
    { title: "Happy Birthday - Chopin and Liszt style", file: "Happy Birthday - Chopin and Liszt style.mid", instrument: "midi" },
    { title: "He's a Pirate - Pirates of the Caribbean [MIDICollection.net]", file: "He's a Pirate - Pirates of the Caribbean [MIDICollection.net].mid", instrument: "midi" },
    { title: "He's a Pirate", file: "He's a Pirate.mid", instrument: "midi" },
    { title: "Hedwig's Theme", file: "Hedwig's Theme.mid", instrument: "midi" },
    { title: "Hopsin - ILL MIND OF HOPSIN 5 (Advanced)", file: "Hopsin - ILL MIND OF HOPSIN 5 (Advanced).mid", instrument: "midi" },
    { title: "Hotel California", file: "Hotel California.mid", instrument: "midi" },
    { title: "Hozier - take me to church (hands are divided)", file: "Hozier - take me to church (hands are divided).mid", instrument: "midi" },
    { title: "Imagine Dragons - Believer", file: "Imagine Dragons - Believer.mid", instrument: "midi" },
    { title: "Imagine Dragons - Demons", file: "Imagine Dragons - Demons (1).mid", instrument: "midi" },
    { title: "Imagine Dragons - Demons", file: "Imagine Dragons - Demons.mid", instrument: "midi" },
    { title: "Imagine Dragons - Follow you (hands are divided)", file: "Imagine Dragons - Follow you (hands are divided).mid", instrument: "midi" },
    { title: "Imagine Dragons - Radioactive (Harder)", file: "Imagine Dragons - Radioactive (Harder).mid", instrument: "midi" },
    { title: "Imagine Dragons - Radioactive", file: "Imagine Dragons - Radioactive.mid", instrument: "midi" },
    { title: "Imagine Dragons x J.I.D - Enemy (hands are divided)", file: "Imagine Dragons x J.I.D - Enemy (hands are divided).mid", instrument: "midi" },
    { title: "Imagine", file: "Imagine.mid", instrument: "midi" },
    { title: "Imogen Heap - Hide And Seek", file: "Imogen_Heap_-_Hide_And_Seek.mid", instrument: "midi" },
    { title: "Inception - Time", file: "Inception - Time.mid", instrument: "midi" },
    { title: "Interstellar", file: "Interstellar.mid", instrument: "midi" },
    { title: "Israel IZ Kamakawiwo'ole - Somewhere over the Rainbow - EASY", file: "Israel IZ Kamakawiwo'ole - Somewhere over the Rainbow - EASY.mid", instrument: "midi" },
    { title: "James Blunt - 1973", file: "James Blunt - 1973.mid", instrument: "midi" },
    { title: "Jaymes Young - Infinity (hands are divided)", file: "Jaymes Young - Infinity (hands are divided).mid", instrument: "midi" },
    { title: "Jessie J - It's My Party", file: "Jessie J - It's My Party.mid", instrument: "midi" },
    { title: "Jessie J - Thunder", file: "Jessie J - Thunder.mid", instrument: "midi" },
    { title: "Johann Pachelbel - Canon in D", file: "Johann Pachelbel - Canon in D.mid", instrument: "midi" },
    { title: "Johann Sebastian Bach - 1st Prelude - EASY", file: "Johann Sebastian Bach - 1st Prelude - EASY.mid", instrument: "midi" },
    { title: "John Legend - All of Me - Pianoitall", file: "John Legend - All of Me - Pianoitall.mid", instrument: "midi" },
    { title: "John Legend - All of Me [Rousseau Cover MIDI]", file: "John Legend - All of Me [Rousseau Cover MIDI].mid", instrument: "midi" },
    { title: "John Newman - Cheating", file: "John Newman - Cheating.mid", instrument: "midi" },
    { title: "K-391 & Alan Walker - Ignite", file: "K-391 & Alan Walker - Ignite (1).mid", instrument: "midi" },
    { title: "K-391 & Alan Walker - Ignite", file: "K-391 & Alan Walker - Ignite.mid", instrument: "midi" },
    { title: "Kelly Clarkson - Catch My Breath (Advanced)", file: "Kelly Clarkson - Catch My Breath (Advanced).mid", instrument: "midi" },
    { title: "Kina - Get you the moon (hands are divided)", file: "Kina - Get you the moon (hands are divided).mid", instrument: "midi" },
    { title: "Lady Gaga - Applause", file: "Lady Gaga - Applause.mid", instrument: "midi" },
    { title: "Lana Del Rey - Young and Beautiful", file: "Lana Del Rey - Young and Beautiful.mid", instrument: "midi" },
    { title: "Les Adieux 1st Movement", file: "Les Adieux 1st Movement.mid", instrument: "midi" },
    { title: "Les Adieux 2nd Movement", file: "Les Adieux 2nd Movement.mid", instrument: "midi" },
    { title: "Let Her Go - Passenger [MIDICollection.net]", file: "Let Her Go - Passenger [MIDICollection.net].mid", instrument: "midi" },
    { title: "Let me down slowly (hands are divided)", file: "Let me down slowly (hands are divided).mid", instrument: "midi" },
    { title: "Let Her Go - Passenger", file: "Let_Her_Go_Passenger.mid", instrument: "midi" },
    { title: "Lewis Capaldi - Someone You Loved", file: "Lewis Capaldi - Someone You Loved.mid", instrument: "midi" },
    { title: "Liebestraum No. 3 in A Major", file: "Liebestraum_No._3_in_A_Major.mid", instrument: "midi" },
    { title: "Lily Allen - Hard Out Here (Hard)", file: "Lily Allen - Hard Out Here (Hard).mid", instrument: "midi" },
    { title: "Linkin Park - Faint (hands are divided)", file: "Linkin Park - Faint (hands are divided).mid", instrument: "midi" },
    { title: "Linkin Park - What I've Done (hands are divided)", file: "Linkin Park - What I've Done (hands are divided).mid", instrument: "midi" },
    { title: "Linkin Park - Numb", file: "Linkin park - Numb.mid", instrument: "midi" },
    { title: "Lorde - Royals (Hard Version)", file: "Lorde - Royals (Hard Version).mid", instrument: "midi" },
    { title: "Lost Woods", file: "Lost Woods.mid", instrument: "midi" },
    { title: "Love Story - Where Do I Begin", file: "Love_Story_-_Where_Do_I_Begin.mid", instrument: "midi" },
    { title: "Lovely by Sawser Natho", file: "Lovely by Sawser Natho.mid", instrument: "midi" },
    { title: "Ludovico Einaudi - Experience (hands are divided) (1)", file: "Ludovico Einaudi - Experience (hands are divided) (1).mid", instrument: "midi" },
    { title: "Ludovico Einaudi - Experience (hands are divided)", file: "Ludovico Einaudi - Experience (hands are divided).mid", instrument: "midi" },
    { title: "Ludwig Van Beethoven - Sonata No.14 Op.27 - Sonata Quasi Una Fantasia - Moonlight Sonata", file: "Ludwig Van Beethoven - Sonata No.14 Op.27 - Sonata Quasi Una Fantasia - Moonlight Sonata.mid", instrument: "midi" },
    { title: "Ludwig Van Beethoven - Sonate No. 17, Tempest, 3rd Movement", file: "Ludwig Van Beethoven - Sonate No. 17, Tempest, 3rd Movement.mid", instrument: "midi" },
    { title: "MIDI File - Hans Zimmer - Interstellar (Atlantic Lights Arrangement + Tempo + Key + Colors)", file: "MIDI File - Hans Zimmer - Interstellar (Atlantic Lights Arrangement + Tempo + Key + Colors).mid", instrument: "midi" },
    { title: "MIDI File - Keane - Somewhere Only We Know (Atlantic Lights Arrangement + Tempo + Colors + Key)", file: "MIDI File - Keane - Somewhere Only We Know (Atlantic Lights Arrangement + Tempo + Colors + Key).mid", instrument: "midi" },
    { title: "MIDI-Collection.zip", file: "MIDI-Collection.zip", instrument: "midi" },
    { title: "Macklemore & Ryan Lewis - Thrift Shop (Advanced)", file: "Macklemore & Ryan Lewis - Thrift Shop (Advanced).mid", instrument: "midi" },
    { title: "Macklemore - White Walls", file: "Macklemore - White Walls.mid", instrument: "midi" },
    { title: "Mad World - Gary Jules", file: "Mad World - Gary Jules.mid", instrument: "midi" },
    { title: "Mad world (hands are divided)", file: "Mad world (hands are divided).mid", instrument: "midi" },
    { title: "Maroon 5 - Daylight (Pianoitall.com)", file: "Maroon 5 - Daylight (Pianoitall.com).mid", instrument: "midi" },
    { title: "Maroon 5 - Love Somebody", file: "Maroon 5 - Love Somebody.mid", instrument: "midi" },
    { title: "Maroon 5 - Payphone", file: "Maroon 5 - Payphone.mid", instrument: "midi" },
    { title: "Marshmello ft. Bastille - Happier", file: "Marshmello ft. Bastille - Happier.mid", instrument: "midi" },
    { title: "Massive Attack - Teardrop", file: "Massive Attack - Teardrop.mid", instrument: "midi" },
    { title: "Metallica - Nothing Else Matters (Advanced)", file: "Metallica - Nothing Else Matters (Advanced).mid", instrument: "midi" },
    { title: "Metallica - Nothing Else Matters (Simple)", file: "Metallica - Nothing Else Matters (Simple).mid", instrument: "midi" },
    { title: "Metallica - Nothing Else Matters Piano Solo", file: "Metallica_-_Nothing_Else_Matters_piano_solo.mid", instrument: "midi" },
    { title: "Miley Cyrus - We Can't Stop (Advanced)", file: "Miley Cyrus - We Can't Stop (Advanced).mid", instrument: "midi" },
    { title: "Monday - Ludovico Einaudi", file: "Monday - Ludovico Einaudi (2)mid.mid", instrument: "midi" },
    { title: "Monday - Ludovico Einaudi", file: "Monday - Ludovico Einaudi.mid", instrument: "midi" },
    { title: "Mozart - Sonata No. 16, 1st Movement K. 545", file: "Mozart - Sonata No. 16, 1st Movement K. 545.mid", instrument: "midi" },
    { title: "My Life Is going on - La casa de papel", file: "My_Life_Is_going_on_-_La_casa_de_papel.mid", instrument: "midi" },
    { title: "Ne-Yo - Let Me Love You (Until You Learn To Love Yourself)", file: "Ne-Yo - Let Me Love You (Until You Learn To Love Yourself).mid", instrument: "midi" },
    { title: "Oasis - Wonderwall (Advanced)", file: "Oasis - Wonderwall (Advanced).mid", instrument: "midi" },
    { title: "Oasis - Wonderwall (Simple)", file: "Oasis - Wonderwall (Simple).mid", instrument: "midi" },
    { title: "Olly Murs - Right Place Right Time (Pianoitall.com)", file: "Olly Murs - Right Place Right Time (Pianoitall.com).mid", instrument: "midi" },
    { title: "One Day", file: "One Day.mid", instrument: "midi" },
    { title: "One Direction - Steal My Girl - Pianoitall", file: "One Direction - Steal My Girl - Pianoitall.mid", instrument: "midi" },
    { title: "OneRepublic - Counting Stars", file: "OneRepublic - Counting Stars.mid", instrument: "midi" },
    { title: "Owl City - Fireflies Piano - Rinerion", file: "Owl City - Fireflies  PIANO - Rinerion.mid", instrument: "midi" },
    { title: "Owl City feat. Carly Rae Jepsen - Good Time", file: "Owl City feat. Carly Rae Jepsen - Good Time.mid", instrument: "midi" },
    { title: "Panic! At The Disco- GirlsGirlsBoys", file: "Panic! At The Disco- GirlsGirlsBoys.mid", instrument: "midi" },
    { title: "Paparazzi", file: "Paparazzi.mid", instrument: "midi" },
    { title: "Paradise", file: "Paradise.mid", instrument: "midi" },
    { title: "Passenger - Let Her Go (hands are divided)", file: "Passenger - Let Her Go (hands are divided).mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - April", file: "Peter Tschaikowsky (Die Jahreszeiten) - April.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - August", file: "Peter Tschaikowsky (Die Jahreszeiten) - August.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - Dezember", file: "Peter Tschaikowsky (Die Jahreszeiten) - Dezember.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - Februar", file: "Peter Tschaikowsky (Die Jahreszeiten) - Februar.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - Januar", file: "Peter Tschaikowsky (Die Jahreszeiten) - Januar.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - Juli", file: "Peter Tschaikowsky (Die Jahreszeiten) - Juli.mid", instrument: "midi" },
    { title: "Peter Tschaikowsky (Die Jahreszeiten) - Juni", file: "Peter Tschaikowsky (Die Jahreszeiten) - Juni.mid", instrument: "midi" },
    { title: "Piano Quick Riff - Last Christmas", file: "Piano Quick Riff - Last Christmas.mid", instrument: "midi" },
    { title: "Piano Man", file: "Piano_Man_Piano.mid", instrument: "midi" },
    { title: "Piano See You Again - Wiz Khalifa", file: "Piano_See_You_Again_Wiz_Khalifa.mid", instrument: "midi" },
    { title: "Pink - Just Give Me A Reason", file: "Pink - Just Give Me A Reason.mid", instrument: "midi" },
    { title: "Pixies - Where is my mind PIANO - Rinerion", file: "Pixies Where is my mind PIANO - Rinerion.mid", instrument: "midi" },
    { title: "Plain White Tea's - Hey There Delilah", file: "Plain White Tea's - Hey There Delilah.mid", instrument: "midi" },
    { title: "Pompeii", file: "Pompeii.mid", instrument: "midi" },
    { title: "QUEEN - Bohemian Rhapsody", file: "QUEEN - Bohemian Rhapsody.mid", instrument: "midi" },
    { title: "RHCP - Under The Bridge", file: "RHCP - Under The Bridge.mid", instrument: "midi" },
    { title: "Rain", file: "Rain.mid", instrument: "midi" },
    { title: "Red Hot Chili Peppers - Under The Bridge (Advanced)", file: "Red Hot Chili Peppers - Under The Bridge (Advanced).mid", instrument: "midi" },
    { title: "Requiem for a Dream Easy", file: "Requiem_for_a_Dream_Easy.mid", instrument: "midi" },
    { title: "Rihanna - Diamonds", file: "Rihanna - Diamonds.mid", instrument: "midi" },
    { title: "Rihanna - Stay (Advanced)", file: "Rihanna - Stay (Advanced).mid", instrument: "midi" },
    { title: "River Flows in You - Yiruma [MIDICollection.net]", file: "River Flows in You - Yiruma [MIDICollection.net].mid", instrument: "midi" },
    { title: "River Flows in You", file: "River Flows in You.mid", instrument: "midi" },
    { title: "River Flows In You", file: "River_Flows_In_You.mid", instrument: "midi" },
    { title: "Roar", file: "Roar.mid", instrument: "midi" },
    { title: "Rocket Man 41k", file: "Rocket Man 41k.mid", instrument: "midi" },
    { title: "Rocket Man", file: "Rocket Man.mid", instrument: "midi" },
    { title: "Ryan Leslie - Maybachs & Diamonds (Advanced) (1)", file: "Ryan Leslie - Maybachs & Diamonds (Advanced) (1).mid", instrument: "midi" },
    { title: "Ryan Leslie - Maybachs & Diamonds (Advanced)", file: "Ryan Leslie - Maybachs & Diamonds (Advanced).mid", instrument: "midi" },
    { title: "Ryan Leslie - Maybachs & Diamonds (Beginner)", file: "Ryan Leslie - Maybachs & Diamonds (Beginner).mid", instrument: "midi" },
    { title: "Sam Smith - Stay With Me - Pianoitall", file: "Sam Smith - Stay With Me - Pianoitall.mid", instrument: "midi" },
    { title: "See You Again - Wiz Khalifa Charlie Puth Piano Tutorial", file: "See_You_Again_-_Wiz_Khalifa__Charlie_Puth_Piano_Tutorial_.mid", instrument: "midi" },
    { title: "See You Again - Wiz Khalifa Charlie Puth Piano Tutorial", file: "See_You_Again_Wiz_Khalifa_Charlie_Puth_Piano_Tutorial.mid", instrument: "midi" },
    { title: "See You Again no rap", file: "See_You_Again_no_rap.mid", instrument: "midi" },
    { title: "Selena Gomez - Come and Get It (Advanced)", file: "Selena Gomez - Come and Get It (Advanced).mid", instrument: "midi" },
    { title: "Sergei Rachmaninoff - Rhapsody on a Theme of Paganini - Variation 18", file: "Sergei Rachmaninoff - Rhapsody on a Theme of Paganini - Variation 18.mid", instrument: "midi" },
    { title: "Serhat Durmus - La C", file: "Serhat Durmus - La Cãƒin.mid", instrument: "midi" },
    { title: "Sia - Snowman (hands are divided)", file: "Sia - Snowman (hands are divided).mid", instrument: "midi" },
    { title: "Sir-Mix-A-Lot - Baby Got Back (Advanced)", file: "Sir-Mix-A-Lot - Baby Got Back (Advanced).mid", instrument: "midi" },
    { title: "Skyfall", file: "Skyfall.mid", instrument: "midi" },
    { title: "Someone you loved", file: "Someone you loved.MID", instrument: "midi" },
    { title: "Sonate No. 14 Moonlight 1st Movement", file: "Sonate_No._14_Moonlight_1st_Movement.mid", instrument: "midi" },
    { title: "Sonate No. 14 Moonlight 3rd Movement", file: "Sonate_No._14_Moonlight_3rd_Movement.mid", instrument: "midi" },
    { title: "Sonatina Opus 36 No. 2 1st Movement", file: "Sonatina Opus 36 No. 2 1st Movement.mid", instrument: "midi" },
    { title: "Song of Storms", file: "Song of Storms.mid", instrument: "midi" },
    { title: "Stairway to Heaven", file: "Stairway_To_Heaven.mid", instrument: "midi" },
    { title: "Stairway to Heaven - Led Zeppelin", file: "Stairway_to_Heaven_-_Led_Zeppelin.mid", instrument: "midi" },
    { title: "Star Wars Main Theme", file: "Star Wars Main Theme.mid", instrument: "midi" },
    { title: "Still Dre - Dr. Dre and Snoop Dogg", file: "Still_Dre_-_Dr._Dre_and_Snoop_Dogg.mid", instrument: "midi" },
    { title: "Still Dre Composition", file: "Still_Dre_Composition.mid", instrument: "midi" },
    { title: "Sting - Shape Of My Heart", file: "Sting - Shape Of My Heart.mid", instrument: "midi" },
    { title: "Stooshe - Black Heart (Advanced)", file: "Stooshe - Black Heart (Advanced).mid", instrument: "midi" },
    { title: "Summertime Sadness", file: "Summertime_Sadness.mid", instrument: "midi" },
    { title: "Super Mario Theme - Pianoitall", file: "Super Mario Theme-Pianoitall.mid", instrument: "midi" },
    { title: "Super Mario Bros. - Main Theme", file: "Super_Mario_Bros._-_Main_Theme.mid", instrument: "midi" },
    { title: "Swedish House Mafia - Dont You Worry Child (Advanced)", file: "Swedish House Mafia - Dont You Worry Child (Advanced).mid", instrument: "midi" },
    { title: "System Of A Down - Chop Suey! (hands are divided)", file: "System Of A Down - Chop Suey! (hands are divided).mid", instrument: "midi" },
    { title: "Sæ´¾ne Mannheims - Und Wenn Ein Lied", file: "Sæ´¾ne Mannheims - Und Wenn Ein Lied.mid", instrument: "midi" },
    { title: "T.I. - Sorry (feat. Andre 3000) (Advanced)", file: "T.I. - Sorry (feat. Andre 3000) (Advanced).mid", instrument: "midi" },
    { title: "THIS", file: "THIS.mid", instrument: "midi" },
    { title: "Taylor Swift - I Knew You Were Trouble", file: "Taylor Swift - I Knew You Were Trouble.mid", instrument: "midi" },
    { title: "Taylor Swift - Shake It Off - Pianoitall", file: "Taylor Swift - Shake It Off - Pianoitall.mid", instrument: "midi" },
    { title: "Taylor Swift - we are never getting back together", file: "Taylor Swift - we are never getting back together.mid", instrument: "midi" },
    { title: "Tchaikovsky Piano Concerto No1", file: "Tchaikovsky_Piano_Concerto_No1.mid", instrument: "midi" },
    { title: "The Black Pearl", file: "The Black Pearl.mid", instrument: "midi" },
    { title: "The Blood Ritual and Moonlight Serenade", file: "The Blood Ritual and Moonlight Serenade.mid", instrument: "midi" },
    { title: "The Final Countdown", file: "The Final Countdown.mid", instrument: "midi" },
    { title: "The Imperial March", file: "The Imperial March.mid", instrument: "midi" },
    { title: "The Knacks - My Sharona (Advanced)", file: "The Knacks - My Sharona (Advanced).mid", instrument: "midi" },
    { title: "The Pink Pather Theme (1)", file: "The Pink Pather Theme (1).MID", instrument: "midi" },
    { title: "The Pink Pather Theme", file: "The Pink Pather Theme.MID", instrument: "midi" },
    { title: "The Police - Every Breath You Take", file: "The Police - Every Breath You Take.mid", instrument: "midi" },
    { title: "The Script feat. will.i.am - Hall of Fame", file: "The Script feat. will.i.am - Hall of Fame.mid", instrument: "midi" },
    { title: "The Weeknd - Save Your Tears (hands are divided)", file: "The Weeknd - Save Your Tears (hands are divided).mid", instrument: "midi" },
    { title: "The Daydream Tears", file: "The-Daydream-Tears.mid", instrument: "midi" },
    { title: "The Office Opening Theme", file: "The_Office__Opening_Theme.mid", instrument: "midi" },
    { title: "Ti Amo", file: "Ti_Amo.mid", instrument: "midi" },
    { title: "Time - Zimmer (hands are divided)", file: "Time - Zimmer (hands are divided).mid", instrument: "midi" },
    { title: "Titanic", file: "Titanic.mid", instrument: "midi" },
    { title: "Titanium", file: "Titanium.mid", instrument: "midi" },
    { title: "Tom Odell - Another love (hands are divided)", file: "Tom Odell - Another love (hands are divided).mid", instrument: "midi" },
    { title: "Trevor Daniel - Falling (hands are divided)", file: "Trevor Daniel - Falling (hands are divided).mid", instrument: "midi" },
    { title: "Turret Opera (Cara Mia) - SATB", file: "Turret Opera (Cara Mia) - SATB.mid", instrument: "midi" },
    { title: "Tuukka Jokilehto - A Love Story - EASY", file: "Tuukka Jokilehto - A Love Story - EASY.mid", instrument: "midi" },
    { title: "Unravel Animenz - Toyko Ghoul", file: "Unravel_Animenz_-_Toyko_Ghoul.mid", instrument: "midi" },
    { title: "Up is Down", file: "Up is Down.mid", instrument: "midi" },
    { title: "Usher - DJ Got Us Falling In Love Again", file: "Usher - DJ Got Us Falling In Love Again.mid", instrument: "midi" },
    { title: "Vanilla Ice - Ice Ice Baby (Advanced)", file: "Vanilla Ice - Ice Ice Baby (Advanced).mid", instrument: "midi" },
    { title: "Voletarium Countdown", file: "Voletarium_Countdown.mid", instrument: "midi" },
    { title: "Waltz No. 2 by Shostakovich", file: "Waltz_No._2_by_Shostakovich.mid", instrument: "midi" },
    { title: "Where is my mind (hands are divided)", file: "Where is my mind (hands are divided).mid", instrument: "midi" },
    { title: "Yann Tiersen - Comptine Dun Autre Ete Lapres Midi", file: "Yann_Tiersen_-_Comptine_Dun_Autre_Ete__Lapres_Midi.mid", instrument: "midi" },
    { title: "Zedd ft. Foxes - Clarity (Pianoitall.com)", file: "Zedd ft. Foxes - Clarity (Pianoitall.com).mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Bolero Of Fire", file: "Zelda Ocarina of Time - Bolero Of Fire.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Fairy Flying", file: "Zelda Ocarina of Time - Fairy Flying.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Fairy Fountain", file: "Zelda Ocarina of Time - Fairy Fountain.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Ganondorfs Theme", file: "Zelda Ocarina of Time - Ganondorfs Theme.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Gerudo Valley", file: "Zelda Ocarina of Time - Gerudo Valley.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Goron City", file: "Zelda Ocarina of Time - Goron City.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Great Deku Trees Last Words", file: "Zelda Ocarina of Time - Great Deku Trees Last Words.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Hyrule Castle Courtyard", file: "Zelda Ocarina of Time - Hyrule Castle Courtyard.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Hyrule Field", file: "Zelda Ocarina of Time - Hyrule Field.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Ice Cavern", file: "Zelda Ocarina of Time - Ice Cavern.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Inside A House", file: "Zelda Ocarina of Time - Inside A House.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Inside The Deku Tree", file: "Zelda Ocarina of Time - Inside The Deku Tree.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Kaepora Gaebora", file: "Zelda Ocarina of Time - Kaepora Gaebora.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Kakariko Village", file: "Zelda Ocarina of Time - Kakariko Village.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Kokiri Forest", file: "Zelda Ocarina of Time - Kokiri Forest.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Kotake And Koume", file: "Zelda Ocarina of Time - Kotake And Koume.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Lon Lon Ranch", file: "Zelda Ocarina of Time - Lon Lon Ranch.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Lost Woods", file: "Zelda Ocarina of Time - Lost Woods.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Market", file: "Zelda Ocarina of Time - Market.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Master Sword", file: "Zelda Ocarina of Time - Master Sword.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Minuet Of Forest", file: "Zelda Ocarina of Time - Minuet Of Forest.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Nocturne Of Shadow", file: "Zelda Ocarina of Time - Nocturne Of Shadow.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Opening Title", file: "Zelda Ocarina of Time - Opening Title.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Potion Shop", file: "Zelda Ocarina of Time - Potion Shop.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Prelude Of Light", file: "Zelda Ocarina of Time - Prelude Of Light.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Requiem Of Spirit", file: "Zelda Ocarina of Time - Requiem Of Spirit.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Serenade Of Water", file: "Zelda Ocarina of Time - Serenade Of Water.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Sheiks Theme", file: "Zelda Ocarina of Time - Sheiks Theme.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Shop", file: "Zelda Ocarina of Time - Shop.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Spirit Temple", file: "Zelda Ocarina of Time - Spirit Temple.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Spiritual Stone", file: "Zelda Ocarina of Time - Spiritual Stone.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Temple Of Time", file: "Zelda Ocarina of Time - Temple Of Time.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Treasure Chest", file: "Zelda Ocarina of Time - Treasure Chest.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Windmill Hut", file: "Zelda Ocarina of Time - Windmill Hut.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Zeldas Lullaby", file: "Zelda Ocarina of Time - Zeldas Lullaby.mid", instrument: "midi" },
    { title: "Zelda Ocarina of Time - Zoras Domain", file: "Zelda Ocarina of Time - Zoras Domain.mid", instrument: "midi" },
    { title: "Zelda's Lullaby", file: "Zelda's Lullaby.mid", instrument: "midi" },
    { title: "As Time Goes By", file: "as_time_goes_by.mid", instrument: "midi" },
    { title: "Believer", file: "beliver.mid", instrument: "midi" },
    { title: "Blink 182 - All the small things MIDI", file: "blink-182_All_the_small_things_MIDI.mid", instrument: "midi" },
    { title: "Brahms Opus 1 No. 1 Format 0", file: "brahms_opus1_1_format0.mid", instrument: "midi" },
    { title: "Brahms Opus 1 No. 2 Format 0", file: "brahms_opus1_2_format0.mid", instrument: "midi" },
    { title: "Brahms Opus 1 No. 3 Format 0", file: "brahms_opus1_3_format0.mid", instrument: "midi" },
    { title: "Brahms Opus 1 No. 4 Format 0", file: "brahms_opus1_4_format0.mid", instrument: "midi" },
    { title: "Break Free - Ariana Grande feat. Zedd (arr. Fonzi M)", file: "break_free_-_ariana_grande_feat._zedd_arr._by_fonzi_m.mid", instrument: "midi" },
    { title: "Bruce Hornsby - The Way It Is", file: "bruce_hornsby-the_way_it_is.mid", instrument: "midi" },
    { title: "Cee Lo Green - Forget You", file: "cee_lo_green-forget_you.mid", instrument: "midi" },
    { title: "Coldplay - A Sky Full Of Stars", file: "coldplay-a_sky_full_of_stars.mid", instrument: "midi" },
    { title: "Deadmau5 - Strobe (Evan Duffy Piano Version)", file: "deadmau5_-_Strobe_(Evan_Duffy_Piano_Version).mid", instrument: "midi" },
    { title: "Deep End (hands are divided)", file: "deep end (hands are divided).mid", instrument: "midi" },
    { title: "Elise Format 0", file: "elise_format0.mid", instrument: "midi" },
    { title: "Keiko Matsui - Midnight Stone", file: "keiko_matsui_midnight_stone.mid", instrument: "midi" },
    { title: "Les Choristes - Cerf Volant", file: "les_choristes_cerf_volant.mid", instrument: "midi" },
    { title: "Les Choristes - Vois Sur Ton Chemin", file: "les_choristes_vois_sur_ton_chemin1.mid", instrument: "midi" },
    { title: "Mak - idk (hands are divided)", file: "mak - idk (hands are divided).mid", instrument: "midi" },
    { title: "Night 2 is ee the stars", file: "night_2_is_ee_the_stars.mid", instrument: "midi" },
    { title: "Nocturne Op. 9 No. 2", file: "nocturne_9_2.mid", instrument: "midi" },
    { title: "Passenger - Let Her Go", file: "passenger-let_her_go.mid", instrument: "midi" },
    { title: "Radioactive", file: "radioactiv.mid", instrument: "midi" },
    { title: "Roxette - Listen To Your Heart", file: "roxette-listen_to_your_heart.mid", instrument: "midi" },
    { title: "Sword Art Online II - Startear Piano by Fonzi M (Remastered)", file: "sao2_-_ed_startear_piano_by_fonzi_m_remastered_for_upload.mid", instrument: "midi" },
    { title: "Sara Bareilles - Love Song", file: "sara_bareilles-love_song.mid", instrument: "midi" },
    { title: "Ses Death", file: "ses_Death.mid", instrument: "midi" },
    { title: "Stay The Night - Zedd ft. Hayley Williams (FonziMGM)", file: "stay_the_night_-_zedd_ft._hayley_williams_fonzimgm.mid", instrument: "midi" },
    { title: "Train - Drops Of Jupiter", file: "train-drops_of_jupiter.mid", instrument: "midi" },
    { title: "Wonderful World", file: "wounderful_world.mid", instrument: "midi" },
    { title: "Yann Tiersen - Comptine Dun Autre Ete", file: "yann_tiersen-comptine_dun_autre_ete.mid", instrument: "midi" }
];

export default songList;
